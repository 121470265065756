import { postRequest, getRequest } from "./httpUtils";

/**
 * Ensures that the given callback is a function. If the callback is not a function,
 * it returns a no-operation function. This is useful for preventing errors when
 * expecting a function as a parameter and receiving something else.
 *
 * @param {*} cb - The callback to ensure is a function.
 * @returns {Function} The original callback if it is a function, or a no-operation function otherwise.
 */
export const ensureFunction = (cb) => {
  if (typeof cb !== "function") {
    return () => {};
  }
  return cb;
};

/**
 * Sanitizes a given filename by replacing all characters that are not alphanumeric, underscore, period, or hyphen
 * with an underscore. This ensures that the filename is safe to use in file systems that may not support certain
 * special characters.
 *
 * @param {string} filename - The original filename to sanitize.
 * @returns {string} The sanitized filename with all unsafe characters replaced by underscores.
 */
export const secureFilename = (filename) =>
  filename.replace(/[^a-zA-Z0-9_.-]/g, "_");

// This is super important for the frontend to know where to send requests when running locally. It's not relevant in production.
export const getBaseURL = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.endsWith(".ngrok.io")
  ) {
    return "http://127.0.0.1:5000"; // This is the correct path of the Flask backend when running locally.
  } else {
    return "";
  }
};

/**
 * Returns a PDF download URL for the given PDF file ID,
 * using the API for private files, and the default blob storage url
 * for public files.
 */


export const getPDFDownloadURL = (pdf_file_id, isPublic = false, fileFormat = "pdf") => {
  return (
    isPublic
      ? `https://tellencl.blob.core.windows.net/10kdatabase/${pdf_file_id}${fileFormat ? `.${fileFormat}` : ""}.pdf`
      : `${getBaseURL()}/api/files/${pdf_file_id}${fileFormat ? `.${fileFormat}` : ""}`

  );
};

/**
 * Returns a download URL for the given file by ID and format.
 */
export const getFileDownloadUrl = (file_id, fileFormat = null, baseUrl = getBaseURL()) => {
  return `${baseUrl}/api/files/${file_id}${fileFormat ? `.${fileFormat}` : ""}`

}

/**
 * Checks if a user has an email address associated with 'tellen.ai' domain.
 *
 * This function takes a user object and checks if the user's primary email address
 * contains the domain '@tellen.ai'. It safely handles cases where the user or
 * email address fields may be undefined, thanks to optional chaining.
 *
 * Don't forget that this is on the frontend, so can easily be manipulated by a user!
 *
 * @param {Object} user - The user object to check. This object should have a
 *                        'primaryEmailAddress' property, which in turn should
 *                        contain an 'emailAddress' property.
 *
 * @returns {boolean} - Returns true if the user's primary email address includes
 *                      '@tellen.ai', otherwise returns false. If the user object
 *                      or the email address is not defined, it also returns false.
 */
export const isTellenUser = (user) => {
  const email = user?.primaryEmailAddress?.emailAddress;
  const fullName = user?.fullName;

  const tellenEmails = ["jonwelzbacher@gmail.com", "@tellen.ai"];
  const tellenNames = ["Deepak Lalit", "Jason Jones"];

  return (
    tellenEmails.some((allowedEmail) => email?.endsWith(allowedEmail)) ||
    tellenNames.includes(fullName)
  );
};

/**
 * Converts an array of URLs to an array of objects containing the URL and the filename.
 *
 * @param {string[]} urls - An array of URLs to be converted.
 * @returns {Object[]} An array of objects, each containing a 'url' and a 'filename'.
 */
export const convertSampleFileURLsToObjects = (urls) =>
  urls.map((url) => {
    const filename = decodeURIComponent(url.split("/").pop());
    return { url, filename };
  });

/**
 * Triggers a file download by sending a POST request to a specified path with JSON data.
 * The server is expected to respond with a blob representing the file content.
 * This function creates a temporary anchor element to initiate the download.
 *
 * @function handleDownload
 * @async
 * @param {string} path - The URL path to send the POST request to.
 * @param {string} extension - The file extension to use when naming the downloaded file.
 * @param {Object|Array<Object>} jsonData - The JSON data to be sent to the server.
 * @param {string} [method="POST"] - The HTTP method to use for the request. Defaults to "POST".
 * @returns {Promise<void>} A promise that resolves once the download is triggered.
 * @throws {Error} If the download fails due to network issues or server errors.
 *
 * @example
 * // Assuming the server generates a PDF file from the JSON data
 * const jsonData = { key: 'value' };
 * handleDownload('/generate_pdf', 'pdf', jsonData)
 *   .then(() => console.log('Download started'))
 *   .catch(err => console.error('Download failed:', err));
 */
export const handleDownload = async (
  path,
  extension,
  jsonData,
  method = "POST"
) => {
  try {
    let response;
    if (method === "POST") {
      response = await postRequest(path, jsonData, "application/json", "blob");
    } else if (method === "GET") {
      response = await getRequest(path, "application/json", "blob");
    } else {
      throw new Error(`Invalid method ${method} provided`);
    }
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `table.${extension}`); // Set the file name with the given extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error(`Download failed: ${error}`);
  }
};

/**
 * Asynchronously triggers a file download for a CSV file generated from the provided JSON data.
 * It posts the JSON data to a specified endpoint ("/api/download_csv"), receives the generated CSV
 * as a blob, creates a URL for it, and triggers the download in the browser.
 *
 * @param {Array<Object>|Object} jsonData - The JSON data to be sent to the server for CSV generation.
 * This can be an array of objects where each object represents a row in the CSV or a single object.
 * 
 * @returns {void} This function does not return a value. It initiates a file download directly in the browser.
 * 
 * @example
 * const data = [
 *   { name: "John Doe", email: "john@example.com" },
 *   { name: "Jane Doe", email: "jane@example.com" }
 * ];
 * 
 * <button
    onClick={() =>
      handleCSVDownload(data)
    }
  >
    Download as CSV
  </button>
 *
 * @throws {Error} Logs an error message to the console if the download fails.
 */
export const handleCSVDownload = (jsonData) =>
  handleDownload("/api/download_csv", "csv", jsonData);

/**
 * Asynchronously triggers a file download for an XLSX file generated from the provided JSON data.
 * It posts the JSON data to the "/api/download_xlsx" endpoint, receives the generated XLSX as a blob,
 * creates a URL for it, and triggers the download in the browser.
 *
 * @function handleXlsxDownload
 * @async
 * @param {Array<Object>|Object} jsonData - The JSON data to be sent to the server for XLSX generation.
 * This can be an array of objects where each object represents a row in the XLSX or a single object.
 *
 * @returns {Promise<void>} A promise that resolves once the download is triggered.
 * @throws {Error} Logs an error message to the console if the download fails.
 *
 * @example
 * const data = [
 *   { name: "John Doe", email: "john@example.com" },
 *   { name: "Jane Doe", email: "jane@example.com" }
 * ];
 *
 * <button
 *     onClick={() =>
 *       handleXlsxDownload(data)
 *     }
 * >
 *   Download as XLSX
 * </button>
 */
export const handleXlsxDownload = (jsonData) =>
  handleDownload("/api/download_xlsx", "xlsx", jsonData);

/**
 * Get a random subset of specified size from an array.
 * If the requested size is greater than the array's length, the entire array is returned.
 * @param {Array} array The original array from which to get a subset.
 * @param {number} size The size of the subset.
 * @return {Array} A random subset of the array or the original array if size exceeds array length.
 */
export const getRandomSubset = (array, size) => {
  if (size >= array.length) {
    return array;
  }

  const shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled.slice(0, size);
};

/**
 * Validates and formats a string as a hexadecimal color.
 *
 * This function checks if the given string `s` is a valid hexadecimal color code.
 * If it is valid but does not start with the '#' symbol, the function formats the string
 * by prefixing it with '#'. If the string is already a valid hex color code that starts
 * with '#', or if it does not match the hex color pattern, the original string is returned.
 *
 * @param {string} s - The string to validate and format as a hex color.
 * @return {string} - The formatted hex color string if valid and necessary, otherwise the original string.
 */
export const makeHexColor = (s) => {
  const hexColorPattern = /^#?([a-fA-F0-9]{6})$/;
  if (hexColorPattern.test(s) && !s.startsWith("#")) {
    return "#" + s;
  }
  return s;
};

export const hexToRgbString = (hex) => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r, g, b;

  if (hex.length === 3) {
    // Handle shorthand notation (e.g., #abc)
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    // Handle full notation (e.g., #aabbcc)
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    throw new Error('Invalid hex color format');
  }

  // Return the color as a comma-separated string
  return `${r}, ${g}, ${b}`;
}

export const classNames = (...classes) => classes?.filter(Boolean).join(" ");

export const getFileExt = (filename) => filename?.split(".").pop();

export function stripHTTP(text) {
  return text.replace("https://", "").replace("www.", "");
}

export function truncateText(text) {
  if (text) {
    let output = stripHTTP(text);
    if (output.length < 80) {
      return output;
    } else {
      return `${output.substring(0, 35)} ... ${output.substring(
        output.length - 35
      )}`;
    }
  }
}

export const errorResponseHandler = (e) => {
  console.info(e);
  if (e.response) {
    throw new Error(e.response.data.message);
  } else {
    throw new Error(e);
  }
};

/**
 * Generates a random UUID (Universally Unique Identifier).
 * @returns {string} A randomly generated UUID.
 */
export function randomUUID() {
  return crypto.randomUUID();
}

/**
 * Finds the last element in an array that satisfies a given predicate function.
 * Iterates over the array from the end to the beginning and returns the first element
 * that matches the predicate. If no element matches the predicate, it returns `undefined`.
 *
 * @param {Array} array - The array to search through.
 * @param {Function} predicate - The function to test each element of the array.
 * @returns {*} The last element in the array that satisfies the predicate, or `undefined` if no such element is found.
 *
 * @example
 * const array = [1, 2, 3, 4, 5];
 * const predicate = (num) => num % 2 === 0;
 * const lastEvenNumber = findLast(array, predicate);
 * console.log(lastEvenNumber); // Output: 4
 */
export const findLast = (array, predicate) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined; // Return undefined if no element satisfies the condition
};

/**
 * Finds the index of the last element in an array that satisfies a given predicate function.
 * Iterates over the array from the end to the beginning and returns the index of the first element
 * that matches the predicate. If no element matches the predicate, it returns -1.
 *
 * @template T
 * @param {Array<T>} array - The array to search through.
 * @param {(item: T) => boolean} predicate - The function to test each element of the array.
 * @returns {number} The index of the last element in the array that satisfies the predicate, or -1 if no such element is found.
 *
 * @example
 * const array = [1, 2, 3, 4, 5, 2];
 * const predicate = (num) => num === 2;
 * const lastIndex = findLastIndex(array, predicate);
 * console.log(lastIndex); // Output: 5
 */
export const findLastIndex = (array, predicate) => {
  if (!Array.isArray(array) || typeof predicate !== 'function') {
    return -1;
  }
  
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }
  
  return -1;
};

/**
 * Creates a deep copy of a serializable object.
 * This function uses `JSON.stringify` to convert the object into a JSON string, and then `JSON.parse` to convert it back into an object.
 * This method is effective for creating a deep copy of objects that are serializable, meaning they can be converted to a JSON string and back without losing information.
 * However, it's important to note that this method has limitations:
 * - It does not work for non-serializable objects, such as functions (including those with closures), Symbols, objects that represent HTML elements in the HTML DOM API, recursive data structures, and many other cases.
 * - It may not accurately copy objects that contain complex data types or circular references, as these are not supported by the JSON format.
 *
 * @param {Object} serializable - The serializable object to be deep copied.
 * @returns {Object} A deep copy of the input object.
 * @throws {SyntaxError} If the input object contains circular references, `JSON.stringify` will throw a `SyntaxError`.
 * @example
 * const original = { a: 1, b: { c: 2 } };
 * const copy = deepCopy(original);
 * console.log(copy); // Output: { a: 1, b: { c: 2 } }
 */
export const deepCopy = (serializable) =>
  JSON.parse(JSON.stringify(serializable));

/**
 * Formats a given timestamp into a human-readable string with a specific format.
 * The formatted string includes the date, time, and uses an em space character for wider spacing between the date and time components.
 *
 * @param {number|string} timestamp - The timestamp to format. This can be a number representing milliseconds since the Unix epoch or a string that can be parsed into a date.
 * @returns {string} A formatted string representing the date and time of the given timestamp. The format is "YYYY-MM-DD HH:MM:SS", where "YYYY" is the four-digit year, "MM" is the two-digit month, "DD" is the two-digit day, "HH" is the two-digit hour, "MM" is the two-digit minute, and "SS" is the two-digit second. An em space character is used for wider spacing between the date and time components.
 * @example
 * // Assuming the current date and time is 2023-04-01 12:34:56
 * const formattedTimestamp = formatTimestamp(Date.now());
 * console.log(formattedTimestamp); // Output: "2023-04-01  12:34:56"
 */
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  // Get the user's timezone offset in hours
  const timezoneOffset = date.getTimezoneOffset() / 60;

  // Formatting the date and time components in the user's local timezone
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();

  // Use an em space character for wider spacing
  const emSpace = "\u2003";

  // Constructing the formatted date-time string with an em space character
  return `${formattedDate}${emSpace}${formattedTime} (UTC${
    timezoneOffset >= 0 ? "-" : "+"
    }${Math.abs(timezoneOffset)})`;
};

export const getAppURL = (data) => {
  let url;
  if (data.custom_app) {
    url = `/${data.based_on}/${data.app_instance_id}`;
  } else {
    url = `/${data.slug}/${data.app_instance_id}`;
  }
  return url.replace(/\/null$/, "");
};

export const formatNum = (number) => {
  if (number) {
    number = Number(number);
    return new Intl.NumberFormat("en-US").format(number);
  }
  return number;
};

export const isIterable = (x) => {
  return x != null && typeof x[Symbol.iterator] === "function";
};

export const toTitleCase = (str) => {
  const lowerCaseWords = [
    "of",
    "in",
    "and",
    "the",
    "on",
    "at",
    "to",
    "for",
    "with",
    "but",
    "or",
    "nor",
    "a",
    "an",
  ];

  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index !== 0 && lowerCaseWords.includes(word)) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const markFileAsOld = (setSuccessfulFileUploads, fileID) => {
  setSuccessfulFileUploads((prev) => {
    const updated = prev.map((file) => {
      if (file.data?.file_id === fileID) {
        return { ...file, isOldFile: true };
      }
      return file;
    });
    return updated;
  });
}

export const markFileAsParsing = (setSuccessfulFileUploads, fileID) => {
  setSuccessfulFileUploads((prev) => {
    const updated = prev.map((file) => {
      if (file.data?.file_id === fileID) {
        return { ...file, isParsing: true, isOldFile: false };
      }
      return file;
    });
    return updated;
  });
}
