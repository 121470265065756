import { NotificationType } from "../notifications/Notifications";
import { postRequestFormData } from "../httpUtils";
import { pdfMimeType, docxMimeType, xlsxMimeType } from "../constants";

/**
 * Handles the file upload process for the application.
 *
 * This function processes multiple files that have been accepted by the user.
 * It prepares each file for upload by creating a FormData object, appending necessary information
 * such as the file itself, a unique file ID generated using `secureFilename` and `uuidv4`, and user information.
 * Then, it sends a POST request to the `/upload` endpoint with the FormData object.
 *
 * @param {File[]} acceptedFiles - An array of File objects representing the files accepted by the user.
 * @param {Object} options - An object containing callback functions and user information.
 * @param {Function} options.addNotification - A callback function to add notifications to the application's UI.
 * @param {string} [options.appInstanceID] - The ID of the app instance.
 * @param {string | null} [options.conversationId] - The ID of the conversation.
 * @param {string} options.fileType - The type of the file being uploaded.
 * @param {Object} options.user - The user object containing user information.
 * @param {Object} actions - An object containing optional callback functions to be executed at various stages of the upload process.
 * @param {Function} [actions.preAction] - An optional function to be executed before the upload process begins.
 * @param {Function} [actions.beforePostAction] - An optional function to be executed before the POST call.
 * @param {Function} [actions.onUploadProgress] - A callback for progress updates during the upload process.
 * @param {Function} [actions.thenAction] - An optional function to be executed after a successful file upload. It receives the file ID, file type, and the response from the server.
 * @param {Function} [actions.catchAction] - An optional function to be executed if an error occurs during the upload process. It receives the file ID and the error object.
 *
 * @example
 * // Example usage:
 * handleFileUpload(
 *   acceptedFiles, // Array of File objects
 *   {
 *     addNotification: addNotification, // Function to add notifications
 *     fileType: "document", // File type
 *     user: user // User object
 *   },
 *   {
 *     preAction: () => console.log('Upload starting...'),
 *     thenAction: (fileID, fileType, response) => console.log(`File ${fileID} uploaded successfully.`),
 *     catchAction: (fileID, error) => console.error(`Error uploading file ${fileID}:`, error)
 *   }
 * );
 */
export const handleFileUpload = async (
  acceptedFiles,
  { addNotification, fileType, user, appInstanceID, conversationId },
  { preAction, thenAction, catchAction, beforePostAction, onUploadProgress },
  uploadInSequence = false
) => {
  if (preAction) {
    preAction();
  }

  const uploadedFiles = [];

  async function uploadFile(file) {
    const mimeType = file.type;
    const validTypes = [
      pdfMimeType,
      docxMimeType,
      xlsxMimeType,
    ];

    // Check if the file's MIME type is either PDF, DOCX, or XLSX
    if (!validTypes.includes(mimeType)) {
      addNotification(
        "Invalid file type.",
        `Only PDF, DOCX, and XLSX files are accepted. The file ${file.name} was not uploaded.`,
        NotificationType.error
      );
      console.log("Invalid file type.");
      return; // Skip the rest of the code for this file
    }
    const data = new FormData();

    data.append("file", file);
    data.append("user", JSON.stringify({ id: user.id }));
    data.append("fileType", mimeType === pdfMimeType ? "pdf" : "docx");
    if (appInstanceID)
      data.append("appInstanceID", appInstanceID);
    if (conversationId)
      data.append("conversation_id", conversationId);

    if (beforePostAction) {
      beforePostAction({ fileType, data, file });
    }

    try {
      const response = await postRequestFormData("/api/upload", data, undefined, { onUploadProgress });
      if (thenAction) {
        thenAction({ file, fileType, response });
      }
      uploadedFiles.push({ name: file.name, id: response.data.id });
    } catch (error) {
      if (catchAction) {
        catchAction({ error });
      }
      addNotification("Error uploading file.", "", NotificationType.error);
      console.error(error);
    } finally {
      console.log(`File upload complete for ${file.name}`);
    }
  }

  if (uploadInSequence) {
    for (const file of acceptedFiles) {
      await uploadFile(file);
    }
  } else {
    await Promise.all(acceptedFiles.map(async (file) => await uploadFile(file)));
  }

  addNotification(
    "File uploaded.",
    uploadedFiles.length === 1 ? `${uploadedFiles[0].name} was correctly uploaded` : `${uploadedFiles.length} files were correctly uploaded`,
    NotificationType.success
  );
};
