import React, { useCallback, useRef } from "react";
import cn from "classnames"
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { useSelector } from "react-redux";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathRoundedSquareIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { extractedFilesSelector } from "../../redux/reducers/app.reducer";
import { Viewer } from "../../utils";
import ConsistencyCheckHTMLTable from "./ConsistencyCheckHTMLTable";

const ConsistencyCheckViewers = ({
  plugins,
  activePDFs,
  isComparing,
  isAnnotating,
  checkOptions,
  processedDates,
  checkOptionKey,
  annotatedPdfData,
  highlightViewers,
  sortedFileEntries,
  isScrollDisabled,
  setIsScrollDisabled,
  isFullScreen,
  setIsFullScreen,
}) => {

  const refViewer1 = useRef(null)
  const refViewer2 = useRef(null)
  const rightPanelRef = useRef(null)

  const [viewerHeight, setViewerHeight] = React.useState()
  const [isHtmlTableVisible, setIsHtmlTableVisible] = React.useState(false)

  const extractedFiles = useSelector(extractedFilesSelector)

  const toolbarPluginInstance1 = toolbarPlugin();
  const { Toolbar: Toolbar1 } = toolbarPluginInstance1;
  const toolbarPluginInstance2 = toolbarPlugin();
  const { Toolbar: Toolbar2 } = toolbarPluginInstance2;

  const [page, setPage] = React.useState([0, 0])
  const [activeTable, setActiveTable] = React.useState(-1)

  React.useEffect(() => {
    if (rightPanelRef.current?.offsetHeight) {
      setViewerHeight(rightPanelRef.current.offsetHeight - 88)
    }
  }, [rightPanelRef])

  const getIsSwitchShow = React.useCallback((viewerIndex) => {
    const pageIndex = page[viewerIndex]
    const highlightedAreas = viewerIndex === 0 ? annotatedPdfData.file1?.[checkOptionKey] : annotatedPdfData.file2?.[checkOptionKey]
    return highlightedAreas?.some(ha => ha.page_number === pageIndex)
  }, [page])

  const genAnnotationTable = useCallback((data) => {
    const output = {}
    if (data && data?.length > 0) {
      data.forEach((item) => {
        if (!output.hasOwnProperty(item.table_name)) output[item.table_name] = []
        output[item.table_name].push(item)
        return output
      })
    }
    return output
  }, [annotatedPdfData.file1, annotatedPdfData.file2, checkOptionKey])

  const toggleHtmlTable = (index) => {
    setIsHtmlTableVisible(!isHtmlTableVisible)
  }
  
  return (
    <>
      {sortedFileEntries && sortedFileEntries?.length > 0 ? (
        <>
          <div className="flex flex-1 h-full overflow-y-scroll gap-1">
            {sortedFileEntries.map(([fileArrayString, arrayValues], index) => {
              const Toolbar = index === 0 ? Toolbar1 : Toolbar2
              const fileId = JSON.parse(fileArrayString)[1]
              const annotationData1 = genAnnotationTable(annotatedPdfData.file1?.[checkOptionKey])
              const annotationData2 = genAnnotationTable(annotatedPdfData.file2?.[checkOptionKey])
              if (index === 1 && checkOptions.mathAccuracy) {
                return null;
              }
              return (
                !checkOptions.mathAccuracy || (checkOptions.mathAccuracy && index === 0)
              ) && (
                <React.Fragment key={fileArrayString}>
                  <div key={fileArrayString} className="flex-1 border rounded-t">
                    <div className="border-b p-2">
                      <div className="text-center font-medium relative">
                        {index === 0
                          ? `Current Year: ${processedDates.currentYear}`
                          : `Previous Year: ${processedDates.previousYear}`}
                          <button
                            onClick={() => toggleHtmlTable(index)}
                            className="absolute right-0"
                          >
                            { !isHtmlTableVisible ? (<>Switch to HTML</>) : (<>Switch to PDF</>)}
                          </button>
                      </div>
                    </div>
                    { !isHtmlTableVisible && (<Toolbar />) }
                    {isAnnotating || isComparing ? (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
                        <div className="text-gray-600">{ isComparing ? "Comparing PDF..." : "Creating annotated PDF..." }</div>
                      </div>
                    ) : (
                      <>
                        <div className="overflow-y-scroll relative" ref={index === 0 ? refViewer1 : refViewer2}>
                            { isHtmlTableVisible && (
                              <div className="absolute w-full h-full bg-white z-1">
                                { extractedFiles[fileId]?.financialStatements?.tables?.map((table, tableKey) => (
                                    <div key={tableKey} className="p-4 bg-gray-50">
                                      <ConsistencyCheckHTMLTable
                                        tableDetails={table.cells || []}
                                        tableTotals={index === 0 ? annotationData1 : annotationData2}
                                        setHighlightAreas={console.log}
                                        fileID={fileId}
                                        jumpToPage={() => {}}
                                      />
                                    </div>
                                ))}
                              </div>
                            )}
                            <Viewer
                              fileUrl={`/api/files/${index === 0 ? activePDFs.file1 : activePDFs.file2}`}
                              plugins={[
                                ...plugins[checkOptions.mathAccuracy ? 1 : index],
                                index === 0 ? toolbarPluginInstance1 : toolbarPluginInstance2
                              ]}
                              styles={{
                                height: (isFullScreen && viewerHeight) ? viewerHeight : 519,
                                maxHeight: (isFullScreen && viewerHeight) ? viewerHeight : 519
                              }}
                              defaultScale={SpecialZoomLevel.PageWidth}
                              onPageChange={(e) => {
                                setPage(prev => index === 0 ? [e.currentPage, prev[1]] : [prev[0], e.currentPage])
                              }}
                            />
                        </div>
                      </>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <div ref={rightPanelRef} className="lg:w-[17rem] sm:w-[17rem] bg-white border-l border-t border-b rounded-l-md px-2">
            <div className="flex flex-row justify-between align-center text-customBlack border-b px-1 py-2">
              { checkOptions.previousYear && "Previous Year Check" }
              { checkOptions.trackedChanges && "Differences" }
              { checkOptions.mathAccuracy && "Summation" }

              <div className="flex flex-row gap-1">
                { !checkOptions.mathAccuracy && (
                  <div
                    className="cursor-pointer"
                    data-tooltip-content={
                      `${isScrollDisabled ? "Start" : "Stop"} scrolling two PDF docs at the same time?`
                    }
                    onClick={() => setIsScrollDisabled(!isScrollDisabled)}
                  >
                    <ArrowPathRoundedSquareIcon
                      className={cn(
                        "w-5 h-5",
                        {
                          "text-gray-400": isScrollDisabled,
                          "text-customHighlightColor": !isScrollDisabled
                        }
                      )}
                    />
                  </div>
                )}

                <div
                  className="cursor-pointer"
                  data-tooltip-content={
                    `${isFullScreen ? "Close" : "Open"} full screen`
                  }
                  onClick={() => setIsFullScreen(!isFullScreen)}
                >
                  { isFullScreen ? (
                    <ArrowsPointingInIcon className="w-5 h-5" />
                  ) : (
                    <ArrowsPointingOutIcon className="w-5 h-5" />
                  ) }
                </div>
              </div>
            </div>
            <div className="overflow-y-scroll h-[calc(100%-3rem)] py-1">
              { checkOptionKey && genAnnotationTable(annotatedPdfData.file1?.[checkOptionKey]) &&
                Object.keys(genAnnotationTable(annotatedPdfData.file1?.[checkOptionKey]) || {})?.map((tableName, tIx) => {
                  const annotationData1 = genAnnotationTable(annotatedPdfData.file1?.[checkOptionKey])
                  const annotationData2 = genAnnotationTable(annotatedPdfData.file2?.[checkOptionKey])
                  return (
                    <div key={tIx} className="bg-gray-100 mb-1 rounded">
                      <div
                        className={
                          cn(
                            "cursor-pointer flex flex-row justify-between items-center py-2 px-2",
                            { "border-b shadow-sm": activeTable === tIx }
                          )
                        }
                        onClick={() => setActiveTable(activeTable === tIx ? -1 : tIx)}
                      >
                        <span className={cn({ "text-black": activeTable === tIx })}>
                          { tableName }
                        </span>
                        <div className="flex flex-row justify-between items-center gap-2">
                          <div>
                            <span className="text-sm font-semibold text-green-500">
                              {
                                annotationData1?.[tableName]?.filter(row => row.is_equal === true)?.length
                              }
                            </span>
                            /
                            <span className="text-sm font-semibold text-red-500">
                              {
                                annotationData1?.[tableName]?.filter(row => row.is_equal === false)?.length
                              }
                            </span>
                          </div>
                          { activeTable === tIx ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                          ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                          )}
                        </div>
                      </div>
                      <div
                        className={cn("px-1 pb-1 max-h-[380px] overflow-y-scroll", {
                          "hidden" : activeTable !== tIx,
                          "block": activeTable === tIx,
                        })}
                      >
                        { annotationData1?.[tableName]?.map((py, pyIx) =>
                          <div
                            key={pyIx}
                            className={cn(
                              "p-2 rounded border-1 my-1 cursor-pointer bg-white",
                              { "border-customHighlightColor shadow-sm": py.active }
                            )}
                            onClick={() => highlightViewers(checkOptionKey, py.id)}
                          >
                            { checkOptionKey === "previousYear" || checkOptionKey === "trackedChanges" ? (
                              <>
                                { py.is_equal ? (
                                  <CheckCircleIcon className="h-6 w-6 text-green-500 mb-1" />
                                ) : (
                                  <XCircleIcon className="h-6 w-6 text-red-500 mb-1" />
                                )}
                                <div className="text-sm">
                                  <div className="relative">
                                    Previous year:
                                    <span className="text-customBlack text-base ml-1">
                                      { py.value }
                                    </span>
                                    <span
                                      className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                                      title={`Page ${py.page_number - 1}`}
                                    >
                                      {py.page_number - 1}
                                    </span>
                                  </div>
                                  { annotationData2?.[tableName]?.[pyIx] && annotationData2?.[tableName]?.[pyIx]?.value && (
                                    <div className="relative">
                                      Current year:
                                      <span className="text-customBlack text-base ml-1">
                                        { annotationData2[tableName][pyIx].value }
                                      </span>
                                      <span
                                        className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                                        title={`Page ${annotationData2[tableName][pyIx].page_number - 1}`}
                                      >
                                        {annotationData2[tableName][pyIx].page_number - 1}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className="text-sm">
                                <div className="relative">
                                  <span className="text-customBlack text-base ml-1">{ py.value }</span>
                                  <span
                                    className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                                    title={`Page ${py.page_number - 1}`}
                                  >
                                    {py.page_number - 1}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                )}
              ) }
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default ConsistencyCheckViewers