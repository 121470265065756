import axios from "axios";
import { SSE } from "sse.js";
import { ensureFunction, getBaseURL } from "./utils";

/**
 * Retrieves the session cookie from the browser's cookies.
 * @returns {string|null} The session cookie value if found, otherwise null.
 */
const getSessionCookie = () => {
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=");
    if (key.trim() === "__session") {
      acc["__session"] = value;
    }
    return acc;
  }, {});

  return cookies["__session"];
};

/**
 * Performs a GET request to the specified path.
 *
 * @param {string} path - The URL path to which the GET request should be made.
 * @param {string} [contentType] - The content type of the request. Defaults to "application/json".
 * @param {string} [responseType] - The response type of the request. Defaults to undefined.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the server.
 * @throws {Error} If the request fails, an error is thrown.
 */
export const getRequest = async (path, contentType = "application/json", responseType = undefined) => {
  const sessionCookie = getSessionCookie();
  const config = {
    headers: {
      "Content-Type": contentType,
    },
  };
  if (responseType) {
    config.responseType = responseType;
  }
  if (sessionCookie) {
    config.headers["Authorization"] = `Bearer ${sessionCookie}`;
  }
  try {
    const response = await axios.get(`${getBaseURL()}${path}`, config);

    return response;
  } catch (error) {
    // Handle error similarly to postRequest
    // For example, you might want to log the error or show a notification
    console.error(error);
    throw error;
  }
};

/**
 * Sends a POST request to the specified path with the provided payload.
 *
 * @param {string} path - The URL path to which the POST request should be made.
 * @param {Object} payload - The data to be sent as the request body.
 * @param {string} [contentType="application/json"] - The content type of the request. Defaults to "application/json".
 * @param {string} [responseType] - The type of data that the server will respond with.
 * @param {callback} [callbacks] - An object with the callback functions for Axios
 * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the server.
 * @throws {Error} If the request fails, an error is thrown.
 */
export const postRequest = async (
  path,
  payload,
  contentType = "application/json",
  responseType,
  callbacks,
) => {
  const sessionCookie = getSessionCookie();
  let config = {
    headers: {
      "Content-Type": contentType,
    },
  };

  // Conditionally add responseType to the config if it's defined
  if (responseType) {
    config.responseType = responseType;
  }

  if (sessionCookie) {
    config.headers["Authorization"] = `Bearer ${sessionCookie}`;
  }

  if (callbacks) {
    config = { ...config, ...callbacks }
    if (callbacks.onUploadProgress) {
      config.onUploadProgress = args => {
        callbacks.onUploadProgress({ ...args, payload })
      }
    }
  }

  // Get the __session cookie
  try {
    const response = await axios.post(
      `${getBaseURL()}${path}`,
      payload,
      config
    );

    return response;
  } catch (error) {
    // addNotification(errorText, "", NotificationType.error);
    throw error;
    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log(error.request);
    // } else {
    //   console.log("Error", error.message);
    // }
    // console.log(error.config);
  }
};

/**
 * Sends a POST request to the specified path with the provided payload as form data.
 * This function is specifically designed for sending multipart/form-data requests.
 *
 * @param {string} path - The URL path to which the POST request should be made.
 * @param {FormData} payload - The FormData object containing the data to be sent as the request body.
 * @param {string} [responseType] - The type of data that the server will respond with.
 * @param {callback} callbacks - An object with the callback functions for Axios.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the server.
 * @throws {Error} If the request fails, an error is thrown.
 */
export const postRequestFormData = (path, payload, responseType, callbacks) =>
  postRequest(path, payload, "multipart/form-data", responseType, callbacks);

/**
 * Sends a DELETE request to the specified path.
 *
 * @param {string} path - The URL path to which the DELETE request should be made.
 * @param {Object} [payload] - Optional data to be sent as the request body.
 * @param {string} [contentType="application/json"] - The content type of the request. Defaults to "application/json".
 * @param {string} [responseType] - The type of data that the server will respond with.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the server.
 * @throws {Error} If the request fails, an error is thrown.
 */
export const deleteRequest = async (
  path,
  payload,
  contentType = "application/json",
  responseType
) => {
  const sessionCookie = getSessionCookie();
  const config = {
    headers: {
      "Content-Type": contentType,
    },
    data: payload, // For DELETE requests, the payload needs to be in the 'data' field
  };

  if (responseType) {
    config.responseType = responseType;
  }

  if (sessionCookie) {
    config.headers["Authorization"] = `Bearer ${sessionCookie}`;
  }

  try {
    const response = await axios.delete(
      `${getBaseURL()}${path}`,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Initiates a Server-Sent Events (SSE) POST request to the specified path with the provided payload.
 * This function is designed to handle streaming data from the server by setting up event listeners for various data types.
 *
 * @param {string} path - The URL path to which the SSE POST request should be made.
 * @param {Object} payload - The data to be sent as the request body. This should be a JSON object.
 * @param {Object} callbacks - An object containing callback functions for handling different types of server-sent events.
 * @param {callback} [callbacks.onStatus] - A callback function to handle status updates from the server.
 * @param {(text: string, conversationMessageId: string) => void} [callbacks.onStreamingText] - A callback function to handle streaming text data from the server.
 * @param {callback} [callbacks.onFinal] - A callback function to handle the final data from the server.
 * @param {callback} [callbacks.onError] - A callback function to handle any errors that occur during the SSE connection.
 * @param {callback} [callbacks.onMetadata] - A callback function to handle metadata updates from the server.
 * @param {callback} [callbacks.onProgress] - A callback function to handle progress updates from the server.
 * @returns {SSE} An SSE object that can be used to control the SSE connection (e.g., to close the connection).
 * @throws {Error} If the request fails to initiate, an error is thrown.
 *
 * @example
 * const source = ssePostRequest('/stream', { type: 'start' }, {
 *   onStatus: (data) => console.log('Status:', data),
 *   onStreamingText: (data) => console.log('Streaming Text:', data),
 *   onFinal: (data) => console.log('Final Data:', data),
 *   onError: (error) => console.error('Error:', error),
 *   onMetadata: (metadata) => console.log('Metadata:', metadata),
 *   onProgress: (progress) => console.log('Progress:', progress),
 * });
 */
export const ssePostRequest = (
  path,
  payload,
  { onStatus, onStreamingText, onFinal, onError, onMetadata, onProgress }
) => {
  onStatus = ensureFunction(onStatus);
  onStreamingText = ensureFunction(onStreamingText);
  onFinal = ensureFunction(onFinal);
  onError = ensureFunction(onError);
  onMetadata = ensureFunction(onMetadata);
  onProgress = ensureFunction(onProgress);

  const source = new SSE(`${getBaseURL()}${path}`, {
    headers: { "Content-Type": "application/json" },
    payload: JSON.stringify(payload),
  });

  source.addEventListener("message", async function (e) {
    const jsonPayload = JSON.parse(e.data);
    if (jsonPayload) {
      console.log(`Received ${jsonPayload.data_type ?? "Unknown"} event.`, jsonPayload);
      try {
        if (!jsonPayload.hasOwnProperty("error")) {
          const dataType = jsonPayload["data_type"];
          if (dataType === "status") {
            await onStatus(jsonPayload);
          } else if (dataType === "streaming_text") {
            console.log("Calling streaming", jsonPayload, Date.now());
            // This is for backwards compatibility for streaming text
            // from the backend that still only sends text,
            // rather than stream_id and text
            if (typeof jsonPayload.value === "object") {
              onStreamingText(jsonPayload.value.text, jsonPayload.value.stream_id);
            } else {
              onStreamingText(jsonPayload);
            }
          } else if (dataType === "final") {
            await onFinal(jsonPayload);
          } else if (dataType === "error") {
            await onError({ ...jsonPayload, payload });
          } else if (dataType === "streaming_metadata") {
            await onMetadata(jsonPayload);
          } else if (dataType === "progress") {
            await onProgress(jsonPayload);
          }
        }
      } catch (error) {
        onError(error);
        console.error("Invalid JSON:", error);
      }
    } else {
      console.log("Received empty message from SSE.");
      onError();
    }
  });

  source.addEventListener("error", async function (e) {
    await onError(e);
  });

  source.stream();

  return source;
};
