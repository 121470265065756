import React, { useCallback, useState } from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';
import Dialog from './Dialog';
import SavedPrompt from './SavedPrompt';

/**
 * @typedef {Object} SavedPrompt
 * @property {string} id - Unique identifier for the saved prompt
 * @property {Array<string>} prompts - Array of prompt strings
 */

/**
 * @typedef {Object} PromptsDialogProps
 * @property {Array<SavedPrompt>} savedPrompts - Array of saved prompts to display in the dialog
 * @property {function(string): void} onSelectPrompt - Function called when a prompt is selected, receives the prompt text
 * @property {string} appInstanceId - Unique identifier for the application instance
 * @property {function(): void} [fetchSavedPrompts] - Optional function to refresh the list of saved prompts
 * @property {function(Event): Promise<void>} [onRunSavedPrompts] - Optional function to run all saved prompts in sequence
 */

/**
 * Dialog component that displays a list of saved prompts and allows the user to select one or run all prompts.
 * 
 * @param {PromptsDialogProps} props - The component props
 * @returns {JSX.Element|null} - Returns the dialog component or null if no saved prompts are available
 * 
 * @example
 * <PromptsDialog
 *   savedPrompts={[{id: '1', prompts: ['What is the revenue for Q1?']}]}
 *   onSelectPrompt={(promptText) => setQuery(promptText)}
 *   appInstanceId="app-123"
 *   fetchSavedPrompts={() => fetchPrompts()}
 *   onRunSavedPrompts={async () => await runAllPrompts()}
 * />
 */
const PromptsDialog = ({ savedPrompts, onSelectPrompt, appInstanceId, fetchSavedPrompts, onRunSavedPrompts }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const handleRunSavedPrompts = useCallback(async (event) => {
        if (onRunSavedPrompts) {
            event.preventDefault();
            onRunSavedPrompts();
            closeModal();
        }
    }, [onRunSavedPrompts, closeModal]);

    if (!Array.isArray(savedPrompts) || savedPrompts.length === 0) {
        return null;
    }

    return (
        <>
            <button
                type="button"
                className="mr-2 shadow h-10 w-10 flex flex-col justify-center items-center rounded-full bg-white text-black"
                onClick={openModal}
            >
                <FolderIcon className="h-5 w-5 text-customHighlightColor" aria-hidden="true" onClick={openModal} />
            </button>

            <Dialog isOpen={isOpen} closeDialog={closeModal} title="Saved Prompts" description="Click any prompt to insert it into the query box.">
                {savedPrompts.map(({ prompts, id }) => (
                    <SavedPrompt
                        key={id}
                        prompts={prompts}
                        id={id}
                        setQuery={onSelectPrompt}
                        appInstanceId={appInstanceId}
                        fetchSavedPrompts={fetchSavedPrompts}
                    />
                ))}
                {onRunSavedPrompts && <div className="mt-2 w-full flex justify-end">
                    <button
                        type="button"
                        className="text-white py-1 px-2 mr-1 rounded text-center bg-customHighlightColor"
                        onClick={handleRunSavedPrompts}
                    >
                        Run Queries
                    </button>
                </div>}
            </Dialog>
        </>
    )
}

export default PromptsDialog;