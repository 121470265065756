// @ts-check
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { ReturnType } from "../../utils/enums";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { handleAddToSavedPrompts } from "../../hooks/addToSavePrompts";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { searchPlugin } from '@react-pdf-viewer/search';
import {
  MagnifyingGlassIcon,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
} from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";
import { useQueryType } from "../../hooks/useQueryType";
import { parseFile } from "../../utils/parseFile";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import SourcesTable from "../../utils/SourcesTable";
import RagQueryComponent from "../../utils/NewRagQueryComponent";
import { postRequest } from "../../utils/httpUtils";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { highlightUtil } from "../../utils/highlightUtil";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import { Viewer } from "../../utils";
import {
  getFilesByAppId,
  filesByAppSelector,
} from "../../redux/reducers/apps.reducer";
import Typed from "react-typed";
import FileUpload from "../../utils/FileUpload/FileUpload";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { secureFilename } from "../../utils";
import useConversation from "../../hooks/useConversation";
import { handleURLSubmit } from "../../utils/handleURLSubmit";
import { getChunksData } from "../../utils/GetChunks";

const GeneralDataChatAnalyzer = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { appInstanceData, instanceId } = useAppInstanceData();
  const [fileIDs, setFileIDs] = useState(
    /** @type {any[]} */ ([])
  ); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState(
    /** @type {any[]} */ ([])
  );
  const [currentPDFURL, setCurrentPDFURL] = useState(
    /** @type {string | null} */ (null)
  );
  // const setCurrentPDFURL = () => {};
  const [currentSource, setCurrentSource] = useState(null);
  const fileType = "General Data Chat"; // TODO: Remove this variable when not needed

  const [responseSource, setResponseSource] = useState(
    /** @type {import('sse.js').SSE | null} */ (null)
  );
  const [addMoreFilesModalOpen, setAddMoreFilesModalOpen] = useState(false);
  
  const [progressFiles, setProgressFiles] = useState(
    /** @type {any[]} */ ([])
  );
  const [accountingQuerySubmitPressed, setAccountingQuerySubmitPressed] = useState(false);
  const {
    conversationId,
    conversationMessages,
    conversationFiles,
    sendUserMessage,
    createConversation,
    loadConversation,
    inProgress,
    deleteConversationFile,
  } = useConversation();

  // Update highlighting
  useEffect(() => {
    if (conversationId) {
      const lastHighlighting = conversationMessages
        .slice()
        .reverse()
        .find((message) => message.highlighting)
        ?.highlighting;
      
      setHighlightAreas(lastHighlighting?.chunks ?? []);
      const relevantFileIds = lastHighlighting?.files ?? [];
      const relevantFiles = successfulFileUploads.filter(file => relevantFileIds.includes(file.data.file_id));
      setRelevantFiles(relevantFiles);
      setChunkLocations(lastHighlighting?.chunks ?? []);
    }
  }, [conversationId, conversationMessages]);

  const queryInput = useRef(
    /** @type {{ value: string } | null } */ (null)
  );
  const moreFilesCancelButtonRef = useRef(null);
  const isReadySearch = useRef(false);

  const [savedPrompts, setSavedPrompts] = useState([]);
  const [doneAddToSavedPrompts, setDoneAddToSavedPrompts] = useState(false);

  const { queryType, setQueryType, queryTypes, queryHeaderText } = useQueryType();

  const { addNotification } = useNotifications();

  const [checklistQuestions, setChecklistQuestions] = useState([]);

  const [highlightAreas, setHighlightAreas] = useState(
    /** @type {import('../../utils/GetChunks').ProcessedChunk[]} */ ([])
  );
  const [chunkLocations, setChunkLocations] = useState(
    /** @type {import('../../utils/GetChunks').ChunkLocation[]} */
    ([])
  );
  const [relevantFiles, setRelevantFiles] = useState(
    /** @type {any[]} */ ([])
  );

  // TODO: remove unused variable
  const setPDFDetails = (details) => {};

  const appFiles = useSelector(filesByAppSelector);

  const shouldSkipResetAnalyzerState = useRef(false);

  // For the upload stuff
  const [isParsing, setIsParsing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [returnType, setReturnType] = useState(ReturnType.NON_FILE);

  const resetAnalyzerState = useCallback(() => {
    if (shouldSkipResetAnalyzerState.current) {
      shouldSkipResetAnalyzerState.current = false;
      return;
    }
    console.log("Resetting analyzer state.");
    setHighlightAreas([]);
    setChunkLocations([]);
    setSuccessfulFileUploads([]);

    if (queryInput && queryInput.current !== null) {
      queryInput.current.value = "";
    }
  }, [
    queryInput,
    setHighlightAreas,
    setChunkLocations,
    setSuccessfulFileUploads,
    shouldSkipResetAnalyzerState,
  ]);

  const { fetchData: fetchSavedPrompts } = useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts,
    addNotification,
    docType: fileType,
    pdfDetails: successfulFileUploads,
    app_instance_id: appInstanceData.app_instance_id,
  });

  /**
   * Handles the form submission in MessageForm.
   * TODO: handleSubmit should be passed the parsed form data as an object.
   */
  const handleSubmit = (/** @type {React.FormEvent<HTMLFormElement>} */ event) => {
    setAccountingQuerySubmitPressed(true);
    setDoneAddToSavedPrompts(false);
    
    const formData = new FormData(
      /** @type {HTMLFormElement} */ (event.target)
    );
    const formDataObj = Object.fromEntries(formData.entries());
    const query = formDataObj.query;
    
    // Prevent the default form submission
    event.preventDefault();
    
    // Call the submitQuery function
    submitQuery(query, queryType);
  };

  const submitQuery = (query, queryType, bypassApprovedAnswers = false, findClosestApproved = false) => {
    setHighlightAreas([]);
    setChunkLocations([]);
    setRelevantFiles([]);

    if (responseSource) {
      console.log("\tClosing SSE connection");
      responseSource.removeEventListener?.("message");
      responseSource.close?.();
    }

    const queries = Array.isArray(query) ? query : [query];

    // Only pass the essential options that can't be determined by useConversation
    const options = {
      system: appInstanceData.customizations.system || "",
      files: fileIDs,
      user: user,
      query_type: queryType,
      file_type: fileType,
      app_instance_id: appInstanceData.app_instance_id,
      bypass_approved_answers: bypassApprovedAnswers,
      find_closest_approved: findClosestApproved,
      return_type: returnType,
    };

    // Use the useConversation hook to handle the chat messaging
    const source = sendUserMessage(queries, options);
    setResponseSource(source);
  };
  
  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("Successful file uploads:", successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentSource(successfulFileUploads[0]);
    } else {
      setAccountingQuerySubmitPressed(false);
      setPDFDetails(null);
      setFileIDs([]);
      setCurrentSource(null);
    }
  }, [successfulFileUploads]);

  useEffect(() => {
    resetAnalyzerState();
    shouldSkipResetAnalyzerState.current = true;
    loadConversation();
  }, []);

  useEffect(() => {
    createConversation(instanceId);
  }, [instanceId]);

  useEffect(() => {
    if (instanceId) {
      dispatch(getFilesByAppId(instanceId));
    }
  }, [instanceId]);

  useEffect(() => {
    // Combine permanent and session files.
    const newFiles = [
      ...(conversationFiles || []),
      ...(appFiles || [])
    ]
      .map(file => ({ data: file }))
      // De-duplicate
      .reduce((acc, file) => {
        const existingFile = acc.find(f => f.data["file_id"] === file.data["file_id"]);
        return existingFile ? acc : [...acc, file];
      }, []);
    setSuccessfulFileUploads(Object.values(newFiles));
  }, [conversationFiles, appFiles]);

  // When the stream is done, user has the option to save this prompt as a default. This handles that button click.
  const handleAddToSavedPromptsButton = (event) =>
    handleAddToSavedPrompts({
      event,
      queryType,
      queryInput,
      queryValues: checklistQuestions,
      savedPrompts,
      setSavedPrompts,
      setDoneAddToSavedPrompts,
      user,
      fileType,
      addNotification,
      app_instance_id: appInstanceData.app_instance_id,
    });

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const searchPluginInstance = searchPlugin();
  const { Search } = searchPluginInstance;

  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data["url"]
  );

  useEffect(() => {
    if (appInstanceData.app_instance_id === instanceId) {
      dispatch(getFilesByAppId(appInstanceData.app_instance_id));
    }
  }, [appInstanceData, instanceId]);

  const analyzerContext = {
    accountingQueryDone: !inProgress,
    accountingQueryOutput: conversationMessages,
    accountingQuerySubmitPressed: accountingQuerySubmitPressed,
    currentReportURL: currentPDFURL,
    currentSource,
    doneAddToSavedPrompts: doneAddToSavedPrompts,
    handleAccountingQuerySubmit: handleSubmit,
    handleAddToSavedPromptsButton: handleAddToSavedPromptsButton,
    boardMinuteDetails: [{ summary: "" }],
    queryHeaderText: queryHeaderText,
    queryInput: queryInput,
    queryType: queryType,
    queryTypes: queryTypes,
    resetAnalyzerState: resetAnalyzerState,
    responseSource: responseSource,
    savedPrompts: savedPrompts,
    setCurrentPDFURL,
    setCurrentSource,
    setQueryType: setQueryType,
    setQueryValues: setChecklistQuestions,
    setSavedPrompts: setSavedPrompts,
    successfulFileUploads: successfulFileUploads,
    chunkLocations: chunkLocations,
    relevantFiles: relevantFiles,
    setAddMoreFilesModalOpen: (p) => {
      setAddMoreFilesModalOpen(p)
      setProgressFiles([])
    },
  };
  const urlInput = useRef(/** @type {HTMLInputElement | null} */ (null));

  /*
   * =============================================================================
   * IMPORTANT: The following constants are set to true/false for the refactoring process. They will be deleted
   * afterward.
   * =============================================================================
   */
  const isDataUploaded = true;
  const urlInputSubmitPressed = false;

  const handleUploadSuccess = useCallback(async (uploadedFiles) => {
    if (uploadedFiles.length > 0) {
      // Reload conversation to get the newly uploaded files
      await loadConversation();
      console.log("Successful file uploads:", uploadedFiles);
      setFileIDs(uploadedFiles.map((file) => file.data["file_id"]));
      setCurrentSource(uploadedFiles[0]);
    } else {
      setAccountingQuerySubmitPressed(false);
      setPDFDetails(null);
      setFileIDs([]);
      setCurrentSource(null);
    }
  }, [loadConversation]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = (t) => {
        setAccountingQuerySubmitPressed(false);
        setPDFDetails(null);

        if (responseSource) {
          responseSource.removeEventListener?.("message");
          responseSource.close?.();
        }
      };

      const beforePostAction = ({ file }) => {
        setProgressFiles(prev => ([
          ...prev,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            progress: 0
          }
        ]))
        setIsUploading(true);
      };

      const finalAction = ({ fileID }) => {
        setIsParsing(false);
      };

      const thenAction = ({ fileID, fileType, response, file }) => {
        setIsUploading(false);
        setIsParsing(true);
        handleUploadSuccess([response]);
        parseFile(
          response.data["file_id"],
          fileType,
          postRequest,
          addNotification,
          NotificationType,
          { catchAction, finalAction },
          appInstanceData.app_instance_id,
          true
        );
      };

      const onUploadProgress = _progress => {
        const { size } = _progress.payload.get("file")
        const { progress } = _progress
        setProgressFiles(prev => prev.map(f => ({ ...f, progress: f.size === size ? progress : f.progress })))
      }

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType, user, appInstanceID: instanceId, conversationId },
        { preAction, thenAction, catchAction, beforePostAction, onUploadProgress },
        false
      );
    },
    [fileIDs, fileType, user, instanceId, conversationId]
  );

  const setURLInputSubmitPressed = () => {};
  const preActionForHandleURLSubmit = () => {};
  const catchAction = () => {};

  const RenderFileUpload = () => (
    <FileUpload
      onDrop={onDrop}
      handleClose={() => {
        setAddMoreFilesModalOpen(false);
        setProgressFiles([]);
      }}
      uploadedFiles={progressFiles}
      handleURLSubmit={(event) =>
        handleURLSubmit(event, {
          addNotification,
          beforeFinalAction({ url }) {
            setIsParsing(false);
          },
          catchAction,
          NotificationType,
          parseFile,
          postRequest,
          preAction: preActionForHandleURLSubmit,
          thenAction({ url }) {
            setIsUploading(false);
            setIsParsing(true);
          },
          secureFilename,
          setFileIDs,
          setPDFDetails,
          setSuccessfulFileUploads,
          setURLInputSubmitPressed,
          urlInput,
          user,
          uuidv4,
        })
      }
      urlInput={urlInput}
    />
  );

  const RenderTitle = () => {
    let result;
    const props = {}; // We are doing this now for the sake of the refactoring process

    if (Object.keys(appInstanceData).length === 0) {
      if ("fileType" in props) {
        result = fileType;
      } else {
        result = "DocumentAI";
      }
    } else {
      result = appInstanceData.custom_name;
    }
    return (
      <h1 className="text-2xl font-bold mb-5">
        <div className="flex justify-between items-center w-full">{result}</div>
      </h1>
    );
  };

  const RenderEdit = () => (
    <h1 className="text-2xl font-bold mb-5">
      <div className="flex justify-end items-center w-fulla">
        <>
          <Link
            to={`/app/edit/${appInstanceData.app_instance_id}`}
            className="ml-2 flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
          >
            Edit App
          </Link>
        </>
      </div>
    </h1>
  );

  const UploadingText = () => (
    <p className="text-sm">
      Uploading<Typed strings={["..."]} loop typeSpeed={40} />
    </p>
  );

  /*
   * =============================================================================
   * IMPORTANT: The following subcomponents are commented out because they will be needed once we
   * fusion with documentAnalyzer. This is going to be crucial for the successful integration process.
   * =============================================================================
   */
  return (
    <Worker
      workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
      key={instanceId}
    >
      <Transition.Root show={addMoreFilesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={moreFilesCancelButtonRef}
          onClose={setAddMoreFilesModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-4 text-left shadow-xl transition-all sm:max-w-3xl sm:p-6">
                  <RenderFileUpload  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="grid grid-cols-12 h-full gap-4">
        {isDataUploaded && (
          <>
            <div
              className={cn("flex flex-col justify-between h-full", {
                "col-span-12": successfulFileUploads.length === 0,
                "col-span-7": successfulFileUploads.length > 0,
              })}
            >
              <div className="flex justify-between">
                <RenderTitle />
                {successfulFileUploads.length === 0 && <RenderEdit />}
              </div>
              <div className="w-full flex-1">
                <div
                  data-testid="rag-query-component"
                  className="flex-1 pb-0 space-x-2 h-full"
                >
                  <RagQueryComponent
                    analyzerContext={analyzerContext}
                    fileIDs={fileIDs}
                    jumpToPage={jumpToPage}
                    userObj={{ id: user?.id }}
                    appInstanceId={appInstanceData.app_instance_id}
                    originalUserText={queryInput?.current?.value}
                    generatedResponse={conversationMessages}
                    submitQuery={submitQuery}
                    returnType={returnType}
                    setReturnType={setReturnType}
                    fetchSavedPrompts={fetchSavedPrompts}
                  />
                </div>
              </div>
            </div>
            <div
              className={cn("col-span-5 bg-white", {
                hidden: successfulFileUploads.length === 0,
              })}
            >
              <RenderEdit />

              <div
                className={cn("overflow-x-scroll scrollbar-hide", {
                  hidden: successfulFileUploads.length === 0,
                })}
                style={{ maxHeight: "calc(100vh - 200px)" }}
              >
                {successfulFileUploads.length === 0 && <RenderFileUpload />}

                <div className="mt-2 p-6">
                  <>
                    {successfulFileUploads.length > 0 && (
                      <>
                        <div className="mb-4">
                          <SourcesTable
                            tableTitle="Permanent Memory Sources"
                            successfulFileUploads={appFiles?.map(file => ({ data: file })) ?? []}
                            currentPDFURL={currentPDFURL}
                            setCurrentPDFURL={setCurrentPDFURL}
                            currentSource={currentSource}
                            setCurrentSource={setCurrentSource}
                            fileType={fileType}
                            shouldSkipResetAnalyzerState={shouldSkipResetAnalyzerState}
                            urlInput={urlInput}
                            urlInputSubmitPressed={urlInputSubmitPressed}
                            showDelete={false}
                          />
                        </div>
                        <div>
                          <SourcesTable
                            tableTitle="In-Session Sources"
                            successfulFileUploads={conversationFiles?.map(file => ({ data: file })) ?? []}
                            currentPDFURL={currentPDFURL}
                            setCurrentPDFURL={setCurrentPDFURL}
                            currentSource={currentSource}
                            setCurrentSource={setCurrentSource}
                            fileType={fileType}
                            setSuccessfulFileUploads={setSuccessfulFileUploads}
                            shouldSkipResetAnalyzerState={shouldSkipResetAnalyzerState}
                            urlInput={urlInput}
                            urlInputSubmitPressed={urlInputSubmitPressed}
                            onDelete={deleteConversationFile}
                            onAddSourceClicked={() => setAddMoreFilesModalOpen(true)}
                          />
                        </div>
                      </>
                    )}

                    {isUploading && <UploadingText />}
                    {isParsing && (
                      <p className="text-sm my-2">
                        Parsing source{fileIDs.length > 1 ? "s" : null}
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      </p>
                    )}

                    {!isParsing &&
                      currentSource && ( // EDIT THIS
                        <div className="flex flex-column border-1 border-customGray-30 mt-6 rounded">
                          <div className="p-3 border-b border-customGray-30">
                            <div className="flex flex-row justify-between">
                              <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                  <MagnifyingGlassIcon className="w-4 h-4" />
                                </div>
                                <Search>
                                  {(props) => {
                                    return (
                                      <input
                                        type="search"
                                        value={props.keyword}
                                        onChange={(e) => {
                                          props.setKeyword(e.target.value);
                                          if (!e.target.value) {
                                            props.clearKeyword()
                                          }
                                          isReadySearch.current = false
                                        }}
                                        onKeyDown={async (e) => {
                                          if (e.keyCode === 13 && props.keyword) {
                                            if (isReadySearch.current === false) {
                                              props.search();
                                            }
                                            else {
                                              props.jumpToNextMatch()
                                            }
                                            isReadySearch.current = true
                                          }
                                        }}
                                        className="block w-full pl-9 text-sm text-gray-900 border border-customGray-30 rounded-1"
                                        placeholder="Search"
                                      />
                                    )
                                  }}
                                </Search>
                              </div>
                              <div className="flex flex-row">
                                <CurrentPageLabel>
                                  {(props) => (
                                    <div className="w-16 px-2 ml-1 flex flex-row items-center justify-between text-black border border-customGray-30 rounded-1 mr-2">
                                      <select
                                        className="p-0 border-0 w-full focus:shadow-none focus:ring-transparent"
                                        onChange={(e) => jumpToPage(Number(e.target.value))}
                                        value={props.currentPage}
                                      >
                                        {Array.from(
                                          { length: props.numberOfPages },
                                          (_, i) => (
                                            <option key={i} value={i}>
                                              {i + 1}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  )}
                                </CurrentPageLabel>
                                <ZoomIn>
                                  {(props) => (
                                    <div
                                      onClick={props.onClick}
                                      className="px-2 flex flex-row items-center justify-center text-black border border-customGray-30 rounded-1 mx-1 cursor-pointer"
                                    >
                                      <MagnifyingGlassPlusIcon className="w-4 h-4 text-customHighlightColor" />
                                    </div>
                                  )}
                                </ZoomIn>

                                <ZoomOut>
                                  {(props) => (
                                    <div
                                      onClick={props.onClick}
                                      className="px-2 flex flex-row items-center justify-center text-black border border-customGray-30 rounded-1 mx-1 cursor-pointer"
                                    >
                                      <MagnifyingGlassMinusIcon className="w-4 h-4 text-customHighlightColor" />
                                    </div>
                                  )}
                                </ZoomOut>
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 p-4 bg-customGray-30">
                            {currentSource && (
                              <Viewer
                                fileUrl={currentSource.data["url"]}
                                plugins={[
                                  highlightPluginInstance,
                                  pageNavigationPluginInstance,
                                  zoomPluginInstance,
                                  searchPluginInstance,
                                ]}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </>
                </div>
              </div>
            </div>
          </>
        )}
        {!isDataUploaded && (
          <div className="flex flex-col col-span-12 justify-center">
            <div className="flex flex-1 p-4 pb-0 space-x-2 h-full w-full justify-between items-center inline">
              <RenderTitle />
              <RenderEdit />
            </div>
            <div className="flex flex-col flex-1 p-4 pb-0 space-x-2 h-full w-full">
              <RenderFileUpload />
              {isUploading && <UploadingText />}
            </div>
          </div>
        )}
      </div>
    </Worker>
  );
};

export default GeneralDataChatAnalyzer;
