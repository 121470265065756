// @ts-check
import React, { useState, useEffect, useCallback } from "react";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import TableDetails from "../../utils/TableDetails";
import { getFileDownloadUrl, getPDFDownloadURL } from "../../utils";
import PropTypes from "prop-types";

/**
 * Shows a list of footnotes and allows selection of a footnote.
 * Auto selects the first footnote when `footnotes` changes.
 */
const FootnotesList = ({ footnotes, isPublic, isLoading }) => {
  const [selectedFootnote, setSelectedFootnote] = useState(/** @type {any} */(null));

  // Auto-select first footnote when footnotes change
  useEffect(() => {
    if (footnotes && footnotes.length > 0) {
      setSelectedFootnote(footnotes[0]);
    } else {
      setSelectedFootnote(null);
    }
  }, [footnotes]);

  // Handle selection interactions
  const handleFootnoteClick = useCallback((footnote) => {
    setSelectedFootnote(footnote);
  }, []);

  const handleCloseDetailView = useCallback(() => {
    setSelectedFootnote(null);
  }, []);

  return (
    <div className="flex h-screen gap-4">
      {/* Left Column - List */}
      <div className="w-1/2 overflow-y-auto border-r border-gray-300 pb-4 px-4">
        {isLoading && (
          <div className="flex justify-center py-4">
            <div className="text-lg text-gray-600">
              Loading footnotes...
            </div>
          </div>
        )}
        {!isLoading && footnotes && footnotes.map(footnote => (
          <FootnoteCard
            key={footnote.id}
            footnote={footnote}
            onClick={handleFootnoteClick}
            selected={selectedFootnote?.id === footnote.id}
          />
        ))}
      </div>

      {/* Right Column - Detail View */}
      <div className="w-1/2 overflow-y-auto flex flex-col h-full bg-white rounded-lg shadow-lg border-2 border-customHighlightColor relative">
        {selectedFootnote ? (
          <FootnoteDetail
            footnote={selectedFootnote}
            onClose={handleCloseDetailView}
            isPublic={isPublic}
          />
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-gray-500">Click a footnote to see details</p>
          </div>
        )}
      </div>
    </div>
  );
};

FootnotesList.propTypes = {
  footnotes: PropTypes.array.isRequired,
  isPublic: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

/**
 * Shows the content of a footnote in a container, with a close button.
 */
const FootnoteDetail = ({ footnote, onClose, isPublic }) => {
  const shouldHighlight = useCallback((footnote, paragraphIndex) => {
    if (!footnote || !footnote.top_paragraphs) {
      return false;
    }

    const topParagraphs = footnote.top_paragraphs;
    
    if (Array.isArray(topParagraphs)) {
      return topParagraphs.includes(paragraphIndex);
    }

    const footnoteIdStr = footnote.footnote_id.toString();
    return topParagraphs[footnoteIdStr]?.includes(paragraphIndex) || false;
  }, []);

  return <>
    <div className="sticky bg-white top-0 right-0">
      <button
        onClick={onClose}
        className="absolute z-10 top-3 right-3 text-gray-400 hover:text-gray-600 bg-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex items-center space-x-2">
        <a
          className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm flex items-center"
          href={getPDFDownloadURL(footnote.pdf_file_id, isPublic)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowDownOnSquareIcon
            className="h-6 w-6 shrink-0 mr-1"
            aria-hidden="true"
          />
          Download 10K
        </a>
      </div>
      <br />
      <br />
      <p className="text-sm font-bold text-purple-700">
        {footnote.company_name}
      </p>
      <p className="text-sm text-gray-700">
        {footnote.industry_name}
      </p>
      <h1 className="text-3xl font-bold text-purple-900 mb-4">
        {footnote.footnote.title}
      </h1>
      <p className="text-sm font-bold text-purple-700">
        {footnote.date?.replace("00:00:00 GMT", "")}
      </p>
      <div className="text-sm text-gray-700 space-y-2 mb-4">
        {footnote.footnote?.paragraphs.map(
          (p, index) =>
            index > 0 && (
              <p
                key={index}
                className={
                  shouldHighlight(footnote, index)
                    ? "bg-yellow-200 border border-yellow-500"
                    : ""
                }
              >
                {p.text}
              </p>
            )
        )}
      </div>
      <div className="text-sm text-gray-700 space-y-2 mb-4">
        {footnote.footnote?.tables?.map((p, index) => (
          <div key={index} className="overflow-x-auto">
            <TableDetails
              tableDetails={p.cells}
              fileID={"N/A"}
              setHighlightAreas={() => {}}
              jumpToPage={() => {}}
            />
          </div>
        ))}
      </div>
      <div className="mt-4">
        <img
          src={
            getFileDownloadUrl(
              footnote.screenshot_file_id,
              undefined,
              isPublic ? "https://tellencl.blob.core.windows.net/10kdatabase" : undefined
            )
          }
          alt="Footnote Screenshot"
          className="w-full h-auto object-contain rounded-md"
        />
      </div>
    </div>
  </>;
};

FootnoteDetail.propTypes = {
  footnote: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isPublic: PropTypes.bool.isRequired
};

/**
 * FootnoteCard component displays a selectable footnote.
 */
const FootnoteCard = ({ footnote, onClick, selected }) => (
  <div
    key={footnote.id}
    onClick={() => onClick(footnote)}
    className={
      `cursor-pointer p-4 mb-4 bg-white rounded-lg transition-all ${selected
          ? "border-2 border-customHighlightColor"
          : "border-2 border-gray-300"
      }`
    }
  >
    <h2 className="text-xl font-semibold text-purple-900 mb-3">
      {footnote.footnote.title}
    </h2>
    <p className="text-sm font-bold text-purple-700 mb-3">
      {footnote.date?.replace("00:00:00 GMT", "")}
    </p>
    <p className="text-sm text-gray-600 mb-3 line-clamp-3">
      {footnote.footnote?.paragraphs[1]?.text}
    </p>
    <p className="text-sm font-bold text-purple-700 mb-3">
      {footnote.company_name ?? footnote.pdfFile?.company_name}
    </p>
    <p className="text-sm text-gray-700">
      {footnote.industry_name ?? footnote.pdfFile?.industry_name}
    </p>
  </div>
);

FootnotesList.propTypes = {
  footnotes: PropTypes.array,
  isPublic: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FootnotesList;