// @ts-check
import React from "react";

/**
 /**
 * Loading Component: A simple loading component that displays a loading message centered
 * vertically and horizontally within a container of specified height.
 */
export const Loading = ({
  /** The text to show while loading (default: Loading...). */
  text = "Loading...",
  /** The height of the container (default: 128px) */
  height = 128
}) => {
  return (
    <div
      className="flex justify-center items-center"
      style={{ height }}
    >
      {text}
    </div>
  );
}
