// @ts-check

import { useCallback, useState } from "react";

/**
 * Hook for management of a paginated table.
 */
export const usePagination = ({ initialPage = 0, initialPageSize = 5 }) => {
    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialPageSize);
    const [totalCount, setTotalCount] = useState(0);

    const onChangePage = useCallback((newPage) => {
      setPage(newPage);
    }, [setPage]);

    const onChangeRowsPerPage = useCallback((rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
      setPage(0);
    }, [setPage, setRowsPerPage]);

    return {
        /** The current page index, 0-based. */
        page,
        /** The current page size. Can be altered through UI interaction. */
        rowsPerPage,
        /** The total number of items in the dataset. */
        totalCount,
        /** Set the total number of items in the dataset. */
        setTotalCount,
        /** Handle changes to the current page from UI. */
        onChangePage,
        /** Handle changes to the page size from UI. */
        onChangeRowsPerPage
    };
};
