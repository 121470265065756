// @ts-check
import React from "react";
import Typed from "react-typed";
import { ArrowDownOnSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getPDFDownloadURL, truncateText } from "./utils";

/**
 * @typedef {Object} SourcesTableProps
 * @property {React.Dispatch<React.SetStateAction<string | null>>} setCurrentPDFURL - Function to set the current PDF URL
 * @property {{ data: string } | null} currentSource - Current source object
 * @property {React.Dispatch<React.SetStateAction<any>>} setCurrentSource - Function to set the current source
 * @property {string | null} fileType - Type of the file
 * @property {React.Dispatch<React.SetStateAction<number>>} [setCurrentInvoiceDetailsIndex] - Function to set current invoice details index
 * @property {Array<any>} [invoiceDetails] - Array of invoice details
 * @property {React.Dispatch<React.SetStateAction<any[]>>} [setSuccessfulFileUploads] - Function to set successful file uploads
 * @property {React.Dispatch<React.SetStateAction<any>>} [setInvoiceDetails] - Function to set invoice details
 * @property {(() => void) | null} [onAddSourceClicked] - Callback for when add source is clicked
 * @property {React.MutableRefObject<boolean>} shouldSkipResetAnalyzerState - Whether to skip resetting analyzer state
 * @property {React.MutableRefObject<HTMLInputElement | null>} urlInput - Reference to URL input
 * @property {boolean} urlInputSubmitPressed - Whether URL input submit was pressed
 * @property {Array<{ data: any, isOldFile?: boolean }>} successfulFileUploads - Array of successful file uploads
 * @property {string} tableTitle - Title of the table
 * @property {string | null} currentPDFURL - Current PDF URL
 * @property {boolean} [showDelete=true] - Whether to show delete button
 * @property {(fileId: string) => void} [onDelete] - Callback for when delete is clicked
 */

/** @type {React.FC<SourcesTableProps>} */
const SourcesTable = ({
  successfulFileUploads = [],
  tableTitle = "",
  currentPDFURL = null,
  setCurrentPDFURL = (url) => { },
  currentSource = null,
  setCurrentSource = (source) => { },
  fileType = null,
  setCurrentInvoiceDetailsIndex = (index) => 0,
  invoiceDetails = [],
  setSuccessfulFileUploads = null,
  setInvoiceDetails = (details) => { },
  onAddSourceClicked = null,
  shouldSkipResetAnalyzerState,
  urlInput = { current: { value: "" } },
  urlInputSubmitPressed = false,
  showDelete = true,
  onDelete = (fileId) => { },
}) => {
  return (
    <div className="max-h-36 overflow-y-scroll osx-sidebar-scrollbar inline-block pr-2">
      <table className="table-auto w-1/2">
        <thead>
          <tr className="border-b border-gray-300">
            {tableTitle && <th className="min-w-[300px]">{tableTitle}</th>}
            {fileType === "Invoice" && (
              <>
                <th className="min-w-[100px]">Date</th>
                <th className="min-w-[250px]">Vendor</th>
                <th className="min-w-[250px]">Customer</th>
                <th className="min-w-[100px]">Amount</th>
              </>
            )}
            <th className="min-w-[50px]"></th>
            {onAddSourceClicked && <th className="min-w-[50px] text-right">
              <button
                className="pr-3, pl-3 text-gray-500 text-sm mt-2 hover:text-customHighlightColor bg-white"
                onClick={onAddSourceClicked}
              >
                +
              </button>
            </th>}
          </tr>
        </thead>
        <tbody>
          {successfulFileUploads.length > 0
            ? successfulFileUploads.map((fileUpload, index) => (
              <tr
                className="border-b border-gray-300 whitespace-nowrap"
                key={`${fileUpload.data["file_id"]}-${index}`}
              >
                <td className="text-sm py-2">
                  <div className="flex flex-row">
                    {currentPDFURL === fileUpload?.data["url"] ||
                      currentSource?.data["url"] === fileUpload?.data["url"] ? (
                      <b>{truncateText(fileUpload.data["filename"])}</b>
                    ) : (
                      <a
                        className="text-gray-600 hover:text-customHighlightColor no-underline"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!shouldSkipResetAnalyzerState) {
                            console.warn(
                              "Resetting Analyzer State feature is not defined"
                            );
                          } else {
                            shouldSkipResetAnalyzerState.current = true;
                          }
                          setCurrentPDFURL(fileUpload.data["url"]);
                          setCurrentSource(fileUpload);
                          if (fileType === "Invoice") {
                            setCurrentInvoiceDetailsIndex(index);
                          }
                        }}
                      >
                        {truncateText(fileUpload.data["filename"])}
                      </a>
                    )}

                    {fileUpload?.isOldFile === false &&
                      <span className="ml-2 text-grey text-sm">
                        Parsing <Typed strings={["..."]} loop typeSpeed={40} />
                      </span>
                    }
                  </div>
                </td>
                {fileType === "Invoice" && (
                  <>
                    <td className="py-2">
                      {invoiceDetails[index] ? (
                        JSON.parse(invoiceDetails[index])?.["Invoice Date"]
                          ?.text
                      ) : (
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      )}
                    </td>
                    <td className="py-2">
                      {invoiceDetails[index] ? (
                        JSON.parse(invoiceDetails[index])?.["Vendor Name"]
                          ?.text
                      ) : (
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      )}
                    </td>
                    <td className="py-2">
                      {invoiceDetails[index] ? (
                        JSON.parse(invoiceDetails[index])?.["Customer Name"]
                          ?.text
                      ) : (
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      )}
                    </td>
                    <td className="py-2">
                      {invoiceDetails[index] ? (
                        JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                          ?.text.symbol === "USD" ? (
                          "$"
                        ) : (
                          JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                            ?.text.symbol
                        )
                      ) : (
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      )}
                      {invoiceDetails[index]
                        ? JSON.parse(invoiceDetails[index])?.["Invoice Total"]
                          ?.text.amount
                        : null}
                    </td>
                  </>
                )}
                <td className="py-2">
                  <a
                    className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                    href={getPDFDownloadURL(fileUpload?.data["file_id"], false)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArrowDownOnSquareIcon
                      className="h-4 w-4 shrink-0"
                      aria-hidden="true"
                    />
                  </a>
                </td>
                {showDelete && (
                  <td className="py-2">
                    <a
                      className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm"
                      href="#"
                      onClick={(e) => {
                        const fileId = fileUpload.data["file_id"];
                        e.preventDefault();
                        onDelete(fileId);

                        if (setSuccessfulFileUploads) {
                          setSuccessfulFileUploads((successfulFileUploads) =>
                            successfulFileUploads.filter(
                              (item) => item.data.file_id !== fileId
                            )
                          );
                        }

                        if (fileType === "Invoice") {
                          setInvoiceDetails((invoiceDetails) =>
                            invoiceDetails.splice(index, 1)
                          );
                        }
                      }}
                    >
                      <TrashIcon
                        className="h-4 w-4 shrink-0"
                        aria-hidden="true"
                      />
                    </a>
                  </td>
                )}
              </tr>
            ))
            : null}
          {urlInputSubmitPressed ? (
            <p className="text-sm py-2">
              {/* {workingText} */}
              {urlInput?.current?.value ? (
                <>
                  {" "}
                  <i>{truncateText(urlInput.current.value)}</i>
                </>
              ) : null}

              <Typed strings={["..."]} loop typeSpeed={40} />
            </p>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default SourcesTable;