// @ts-check
import React, { useCallback } from "react";
import './TablePagination.css';

/**
 * @typedef {Object} TablePaginationProps
 * @property {number} count - Total number of items
 * @property {number} page - Current page number (0-based)
 * @property {(page: number) => void} onPageChange - Callback when page changes
 * @property {number} rowsPerPage - Number of rows per page
 * @property {(rowsPerPage: number) => void} onRowsPerPageChange - Callback when rows per page changes
 * @property {number[]} rowsPerPageOptions - Available options for rows per page
 */

/**
 * TablePagination component provides pagination controls for tables
 * including page selection and rows per page options.
 * 
 * @param {TablePaginationProps} props
 * @returns {React.JSX.Element}
 */
export const TablePagination = ({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  rowsPerPageOptions,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  /**
   * Handles page change events from the page selector
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  const handlePageChange = useCallback((event) => {
    const newPage = parseInt(event.target.value, 10);
    onPageChange(newPage);
  }, [onPageChange]);

  /**
   * Handles rows per page change events from the rows per page selector
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  const handleRowsPerPageChange = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage);
  }, [onRowsPerPageChange]);

  return (
    <div className="pagination-container">
      <div className="rows-per-page-selector">
        <label htmlFor="rows-per-page">Rows per page:</label>
        <select
          id="rows-per-page"
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
        >
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {totalPages > 1 && (
        <div className="page-selector">
          <label htmlFor="page">Page:</label>
          <select id="page" value={page} onChange={handlePageChange}>
            {Array.from({ length: totalPages }, (_, index) => (
              <option key={index} value={index}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
