// @ts-check
import { NotificationType } from "./notifications/Notifications";
import { ssePostRequest } from "./httpUtils";

/**
 * @typedef {Object} ChunkRequestPayload
 * @property {string} query - The user's query text
 * @property {boolean} [streaming=true] - Whether to stream the response
 * @property {Array<string>} files - Array of file IDs to search for chunks
 * @property {string} [user] - User identifier
 * @property {string} [query_type] - Type of query (e.g., "accounting")
 * @property {boolean} [get_chunks_only=false] - Whether to only get chunks without generating a response
 * @property {string} [file_type] - Type of files being processed
 * @property {Array<Object>} [history] - Conversation history
 * @property {string} [conversation_id] - ID of the conversation
 * @property {string} [app_instance_id] - ID of the app instance
 * @property {boolean} [bypass_approved_answers] - Whether to bypass approved answers
 * @property {boolean} [find_closest_approved] - Whether to find the closest approved answer
 * @property {string} [return_type] - Type of return value expected
 * @property {string} [system] - System prompt
 */

/**
 * @typedef {Object} FileUpload
 * @property {string} file_id - ID of the uploaded file
 * @property {Object} data - Additional data about the file
 * @property {string} data.file_id - ID of the file (same as file_id)
 * @property {string} [data.file_name] - Name of the file
 * @property {string} [data.file_type] - Type of the file
 * @property {number} [data.file_size] - Size of the file in bytes
 */

/**
 * A helper function that fetches chunks of a PDF document from the server.
 * This function makes a request to the server to get chunks of a PDF document
 * and returns a promise that resolves with the fetched data.
 * It uses the promise pattern for backwards compatibility purposes.
 *
 * @param {ChunkRequestPayload} payload - The payload to be sent to the server.
 * @returns {Promise<Object>} A promise that resolves with the fetched data from the server.
 * @throws Will throw an error if there is a problem fetching the chunks.
 */
const getChunksInternal = (payload) => new Promise((resolve, reject) => {
  const payloadForChunksOnly = { ...payload };
  payloadForChunksOnly.get_chunks_only = true;
  try {
    const source = ssePostRequest("/api/query_chatbot", payloadForChunksOnly, {
      onFinal(payload) {
        source.close?.();
        resolve(payload.value);
      },
      onError(error) {
        source.close?.();
        reject(error)
      }
    });
  } catch (error) {
    reject(error);
  }
});

/**
 * @typedef {Object} ProcessedChunk
 * @property {number} pageIndex - Zero-based page index where the chunk is located
 * @property {number} height - Height of the chunk as a percentage of the page height
 * @property {number} width - Width of the chunk as a percentage of the page width
 * @property {number} left - Left position of the chunk as a percentage of the page width
 * @property {number} top - Top position of the chunk as a percentage of the page height
 * @property {string} file_id - ID of the file this chunk belongs to
 */

/**
 * @typedef {Object} ChunkLocation
 * @property {number} pageIndex - Page number where the chunk is located (1-indexed)
 * @property {string} file_id - ID of the file this chunk belongs to
 */

/**
 * @typedef {Object} GetChunksDataResult
 * @property {Array<ProcessedChunk>} highlightAreas - Array of processed chunks for highlighting
 * @property {Array<ChunkLocation>} chunkLocations - Array of chunk locations
 * @property {Array<Object>} relevantFiles - Array of relevant files
 * @property {Array<Array>} rawChunks - Raw chunks data from the API
 */

/**
 * Gets chunks data for the conversation
 * @param {ChunkRequestPayload} payload - The payload for the request
 * @param {Array<FileUpload>} successfulFileUploads - Array of successful file uploads
 * @returns {Promise<GetChunksDataResult>} Object containing processed chunks data
 * @throws Will throw an error if there is a problem fetching or processing the chunks
 */
export const getChunksData = async (payload, successfulFileUploads) => {
  try {
    const response = await getChunksInternal(payload);
    const chunks = response.chunks;
    const filesBasedOnSummaries = response.files_based_on_summaries.map(
      (fileId) => {
        return successfulFileUploads.find(
          (file) => file.data.file_id === fileId
        );
      }
    );

    // Process chunks to calculate their dimensions and positions
    const processedChunks = processChunks(chunks);

    // Extract unique page indexes for chunk locations
    const chunkLocations = processedChunks
      .reduce((acc, current) => {
        // Check if an object with the same pageIndex and file_id already exists in the accumulator
        const duplicateIndex = acc.findIndex(
          (obj) =>
            obj.pageIndex === current.pageIndex &&
            obj.file_id === current.file_id
        );

        // If no duplicate is found, add the current object to the accumulator
        if (duplicateIndex === -1) {
          acc.push({
            pageIndex: current.pageIndex,
            file_id: current.file_id,
          });
        }

        return acc;
      }, /** @type {ChunkLocation[]} */ ([]))
      .map((obj) => ({ pageIndex: obj.pageIndex + 1, file_id: obj.file_id }))
      .sort((a, b) => a.pageIndex - b.pageIndex);

    return {
      highlightAreas: processedChunks,
      chunkLocations: chunkLocations,
      relevantFiles: filesBasedOnSummaries,
      rawChunks: chunks
    };
  } catch (error) {
    console.error("Error getting chunks data:", error);
    throw error;
  }
};

/**
 * Transform server chunks into an array of objects.
 * @param {any[]} payloadChunks 
 * @returns {ProcessedChunk[]} Array of processed chunks.
 */
export function processChunks(payloadChunks) {
  return payloadChunks.map((chunk) => {
    return /** @type {ProcessedChunk} */ ({
      pageIndex: chunk[1] - 1,
      height: (chunk[5] / chunk[7]) * 100,
      width: (chunk[4] / chunk[6]) * 100,
      left: (chunk[3] / chunk[6]) * 100,
      top: (chunk[2] / chunk[7]) * 100,
      file_id: chunk[8],
    });
  });
}

/**
 * Fetches chunks of a PDF document and processes them to extract relevant information.
 * This function makes a request to the server to get chunks of a PDF document,
 * processes these chunks to calculate their dimensions and positions, and then
 * updates the state with the processed chunks and relevant files.
 *
 * @param {Object} options - The options object containing callback functions and payload.
 * @param {Function} [options.addNotification] - A function to display notifications.
 * @param {ChunkRequestPayload} options.payload - The payload to be sent to the server.
 * @param {Function} options.setChunkLocations - A function to update the chunk locations state.
 * @param {Function} options.setHighlightAreas - A function to update the highlight areas state.
 * @param {Function} options.setRelevantFiles - A function to update the relevant files state.
 * @param {Array<FileUpload>} options.successfulFileUploads - An array of successfully uploaded files.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 * @throws Will throw an error if there is a problem fetching or processing the chunks.
 */
const getChunks = async ({
  addNotification,
  payload,
  setChunkLocations,
  setHighlightAreas,
  setRelevantFiles,
  successfulFileUploads,
}) => {
  try {
    // Use the getChunksData function to get the processed data
    const chunksData = await getChunksData(payload, successfulFileUploads);
    
    // Update state with the processed data
    setHighlightAreas(chunksData.highlightAreas);
    setChunkLocations(chunksData.chunkLocations);
    
    if (setRelevantFiles) {
      setRelevantFiles(chunksData.relevantFiles);
    } else {
      console.warn(
        "setRelevantFiles is not defined. Relevant files will not be set."
      );
    }
  } catch (error) {
    console.log("error", error)
    addNotification && addNotification(
      "Error getting relevant highlights from PDF.",
      "",
      NotificationType.error
    );
    if (!addNotification) {
      console.warn(
        "addNotification is not defined. No notification will be shown."
      );
    }
  }
};

export default getChunks;
