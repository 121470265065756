import { useEffect, useRef, useCallback } from "react";
import { NotificationType } from "../../utils/notifications/Notifications";
import { postRequest } from "../../utils/httpUtils";

export const useFetchSavedPrompts = ({
  user,
  queryType,
  setSavedPrompts,
  addNotification,
  docType,
  pdfDetails,
  app_instance_id
}) => {
  const lastFetched = useRef(null);
  const refPdfDetails = useRef(pdfDetails);
  const refDocType = useRef(docType);
  const refQueryType = useRef(queryType);
  const throttleTime = 1000; // Milliseconds

  const fetchData = useCallback(async () => {
    const now = Date.now();

    // Check if enough time has elapsed
    if (
      lastFetched.current !== null &&
      now - lastFetched.current < throttleTime &&
      !(
        pdfDetails !== refPdfDetails.current ||
        docType !== refDocType.current ||
        queryType !== refQueryType.current
      )
    ) {
      return; // Exit if not enough time has passed
    }

    try {
      console.log(docType);
      const response = await postRequest("/api/get_saved_prompts", {
        user: user,
        app_instance_id: app_instance_id,
      });
      if (response.data == null) {
        setSavedPrompts([]);
      } else {
        console.log("Setting saved prompts from server", response.data);
        setSavedPrompts(response.data);
      }
      lastFetched.current = Date.now(); // Set lastFetched to now
    } catch (error) {
      console.log("Error fetching saved prompts", error);
      addNotification(
        "Error getting your saved prompts.",
        "",
        NotificationType.error
      );
    }
  }, [user, app_instance_id, docType, pdfDetails, queryType, setSavedPrompts, addNotification]); // Dependencies that trigger recreation of the callback

  useEffect(() => {
    if (user && app_instance_id) {
      fetchData();
    } else {
      setSavedPrompts([]);
    }
    refDocType.current = docType;
    refPdfDetails.current = pdfDetails;
    refQueryType.current = queryType;
  }, [user, queryType, docType, addNotification, pdfDetails, app_instance_id, fetchData]);

  // Return fetchData to allow external usage
  return { fetchData };
};
