// @ts-check
import React from "react";
import Typed from "react-typed";

/**
 * Collection of patterns and their JSX replacements for text formatting
 * Each entry contains a regex pattern and a replacement function or JSX element
 * Used to transform plain text into formatted JSX with links and styled sections
 * 
 * @type {Array<{pattern: RegExp, replacement: React.ReactNode | ((match: string) => React.ReactNode)}>}
 */
const patternsAndReplacements = [
  {
    pattern: /(?<!>)IAS \d+(?!<)/g,
    replacement: (match) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`/redirect/${match.replace(/\s+/g, "")}`}
      >
        {match}
      </a>
    ),
  },
  {
    pattern: /(?<!>)IFRS \d+(?!<)/g,
    replacement: (match) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`/redirect/${match.replace(/\s+/g, "")}`}
      >
        {match}
      </a>
    ),
  },
  {
    pattern: /(?<!>)Topic \d+(-\d+)*(?!<)/g,
    replacement: (match) => {
      const numberPart = match.replace("ASC ", "");
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://asc.fasb.org/${numberPart}/showallinonepage`}
        >
          {match}
        </a>
      );
    },
  },
  {
    pattern: /(?<!>)ASC \d+(-\d+)*(?!<)/g,
    replacement: (match) => {
      const numberPart = match.replace("ASC ", "");
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://asc.fasb.org/${numberPart}/showallinonepage`}
        >
          {match}
        </a>
      );
    },
  },
  {
    pattern: /(?<!>)IRS Publication \d+(-\d+)*(?!<)/g,
    replacement: (match) => {
      const numberPart = match.replace("IRS Publication ", "");
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.irs.gov/pub/irs-pdf/p${numberPart}.pdf`}
        >
          {match}
        </a>
      );
    },
  },
  // {
  //   pattern: /(?<!>)ASU \d+(-\d+)*(?!<)/g,
  //   replacement: (match) => (
  //     <a target="_blank" rel="noreferrer"
  //       href={`https://www.example.com/${match
  //         .replace(/\s+/g, "")
  //         .toLowerCase()}`}
  //     >
  //       {match}
  //     </a>
  //   ),
  // },
  {
    pattern: /Introduction:/g,
    replacement: (
      <>
        <b>Introduction</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Topic Exploration:/g,
    replacement: (
      <>
        <b>Topic Exploration</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Search Assistance:/g,
    replacement: (
      <>
        <b>Search Assistance</b>
        <br />
      </>
    ),
  },
  {
    pattern: /General Knowledge:/g,
    replacement: (
      <>
        <b>General Knowledge</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Recent Updates or Changes:/g,
    replacement: (
      <>
        <b>Recent Updates or Changes</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Examples and Scenarios:/g,
    replacement: (
      <>
        <b>Examples and Scenarios</b>
        <br />
      </>
    ),
  },
  {
    pattern: /FAQs:/g,
    replacement: (
      <>
        <b>FAQs</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Guidance and Interpretations:/g,
    replacement: (
      <>
        <b>Guidance and Interpretations</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Impact on Financial Statements:/g,
    replacement: (
      <>
        <b>Impact on Financial Statements</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Practical Application:/g,
    replacement: (
      <>
        <b>Practical Application</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Query:/g,
    replacement: (
      <>
        <b>Query</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Sources:/g,
    replacement: (
      <>
        <b>Sources</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Explanation:/g,
    replacement: (
      <>
        <b>Explanation</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Key Guidelines and Requirements:/g,
    replacement: (
      <>
        <b>Key Guidelines and Requirements</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Explanation of Concept:/g,
    replacement: (
      <>
        <b>Explanation of Concept</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Standard Overview:/g,
    replacement: (
      <>
        <b>Standard Overview</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Industry Challenges:/g,
    replacement: (
      <>
        <b>Industry Challenges</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Application Examples:/g,
    replacement: (
      <>
        <b>Application Examples</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Relevant Standards:/g,
    replacement: (
      <>
        <b>Relevant Standards</b>
        <br />
      </>
    ),
  },
  {
    pattern: /Industry Overview:/g,
    replacement: (
      <>
        <b>Industry Overview</b>
        <br />
      </>
    ),
  },
];

/**
 * Formats text by replacing patterns with styled JSX elements
 * 
 * @param {Object} props - Component props
 * @param {string} props.text - The text to format
 * @returns {React.ReactNode} Formatted text with links and styled sections
 */
const FormatText = ({ text }) => {
  // https://chat.openai.com/c/5c3b1d7d-4cc7-441b-a69c-9e2a8f407fdf
  // Interesting problem to solve given that the JSX is an object and we don't want to use dangerouslySetInnerHTML...
  
  let matches = [];
  patternsAndReplacements.forEach(({ pattern, replacement }) => {
    let match;
    while ((match = pattern.exec(text)) !== null) {
      matches.push({
        value: match[0],
        index: match.index,
        replacement:
          typeof replacement === "function"
            ? replacement(match[0])
            : replacement,
      });
    }
  });
  matches.sort((a, b) => a.index - b.index);

  let lastIndex = 0;
  const elements = [];
  matches.forEach((match, i) => {
    elements.push(text.substring(lastIndex, match.index));
    elements.push(<React.Fragment key={i}>{match.replacement}</React.Fragment>);
    lastIndex = match.index + match.value.length;
  });
  elements.push(text.substring(lastIndex));

  return <>{elements}</>;
};

/**
 * Renders text with optional typing animation and special formatting
 * 
 * This component displays text with either a typing animation (when not done)
 * or as fully formatted text with special patterns transformed into links and
 * styled sections. Used primarily for chat messages and responses.
 * 
 * @param {Object} props - Component props
 * @param {string} props.text - The text to display
 * @param {boolean} props.done - Whether the text is complete (true) or still streaming (false)
 * @returns {React.ReactNode} Text with optional typing animation and formatting
 */
const StreamingText = ({ text, done }) => {
  const items = text && typeof text === "string" ? text.split("\n") : [];

  return items?.map((item, key) => (
    <span key={key}>
      <FormatText text={item} />
      {item !== "" && key === items.length - 1 && done === false && (
        <Typed strings={[""]} loop typeSpeed={40} />
      )}
      <br />
    </span>
  ));
};

export default StreamingText;
