import { useState } from 'react';

/**
 * `useQueryType` is a custom React hook for managing the type of query in the application.
 */
export const useQueryType = () => {
  const queryTypes = ['Natural Language', 'Data Extraction'];
  const [queryType, setInternalQueryType] = useState(queryTypes[0]);
  const [error, setError] = useState(null);
  let queryHeaderText;
  switch (queryType) {
    case "Natural Language":
      queryHeaderText = "Ask a question:";
      break;
    case "Data Extraction":
      queryHeaderText = "What data would you like to extract?";
      break;
    default:
      throw new Error(`Unexpected query type: ${queryType}`);
  }

  const setQueryType = (newQueryType) => {
    if (queryTypes.includes(newQueryType)) {
      setInternalQueryType(newQueryType);
      setError(null); // clear any previous error
    } else {
      setError("Wrong query type");
    }
  };

  return {
    queryType,
    setQueryType,
    error,
    queryTypes,
    queryHeaderText
  };
}