import React, { useEffect, useState } from "react";
import { Select } from "../../components/select"
import { getRequest } from "../../utils/httpUtils";

export const ParsedFilesDropdown = ({
  onFileSelect
}) => {

  const [cachedFiles, setCachedFiles] = useState()
  const [selectedCachedFile, setSelectedCachedFile] = useState()

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest('/api/ic_check/history');

        if (data && data?.length) {
          setCachedFiles(data)
        }
      }
      catch(err) {
        console.log("Error fetching parsed files", err)
      }
    })()
  }, [])

  const handleCachedFileChanged = (_selectedFile) => {
    if (_selectedFile && _selectedFile?.length) {
      onFileSelect(cachedFiles?.find((({ file1, file2 }) => `${file1.id}-${file2.id}` === _selectedFile[0].value)))
    }
  }

  return (
    <div>
      <Select
        label="Choose comparison"
        options={cachedFiles?.map(({ file1, file2 }) => ({
          value: `${file1.id}-${file2.id}`,
          label: `${file1.name} - ${file2.name}`,
        }))}
        value={selectedCachedFile}
        onChange={handleCachedFileChanged}
        isClearable
        isMulti
        placeholder="Choose comparison"
      />
  </div>
  )
}
