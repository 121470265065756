// @ts-check
import React from "react";

/**
 * @typedef {Object} SummaryProps
 * @property {Object} metadata - Metadata containing summary information
 * @property {Array<{file_id: string, filename: string, summary: string}>} metadata.summaries - Array of file summaries
 */

/**
 * Component to display summaries of files that were used
 * @type {React.FC<SummaryProps>}
 */
export const Summary = ({ metadata }) => {
  return metadata.summaries && metadata.summaries.length > 0 ? (
    <p>
      <i>Here are the summaries of the files that were used: </i>
      <br />
      <br />
      <ul>
        {metadata.summaries?.map(({ file_id, filename, summary }) => (
          <li key={file_id}>
            <span className="font-bold">{filename}</span>: {summary}
          </li>
        ))}
      </ul>
    </p>
  ) : null;
};
