// @ts-check
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest, postRequest, deleteRequest, ssePostRequest } from "../utils/httpUtils";
import { randomUUID } from "../utils/utils";
import { processChunks } from "../utils/GetChunks";

// Uncomment test URL to use a preset streaming routine.
// const queryChatBotUrl = "/api/query_chatbot_test";
const queryChatBotUrl = "/api/query_chatbot";

/**
 * A conversation message within a conversation.
 *
 * @typedef {Object} ConversationMessage
 * @property {string} message - The content of the message
 * @property {string} role - The role of the message sender, can be "user", "assistant", or "system"
 * @property {boolean} completed - Whether the message is complete (true) or still streaming (false)
 * @property {string} uuid - Unique identifier for the message
 * @property {string} [id] - Unique identifier for the message
 * @property {string} conversation_id - The conversation ID.
 * @property {string} [created_at] - Optional timestamp when the message was created
 * @property {string} [file_url] - Optional URL to a file associated with the message
 * @property {boolean} [error] - Optional flag indicating if the message encountered an error
 * @property {string} [error_message] - Optional error message if the message encountered an error
 * @property {string} [approved_answer] - Optional approved answer for the message
 * @property {string} [approved_question] - Optional approved question for the message
 * @property {any} [metadata] - Optional metadata associated with the message, used in Summary.
 * @property {any[]} [statuses] - Status messages shown if present, see BlackComputerScreen.
 * @property {boolean} [dynamic_answer] - Optional flag indicating if the answer is dynamic
 * @property {{ chunks: import("../utils/GetChunks").ProcessedChunk[], files: string[] }} [highlighting] - The final result of the message sent with the "final" SSE event.
 */

/**
 * A conversation object.
 *
 * @typedef {Object} Conversation
 * @property {string} conversation_id - Unique identifier for the conversation
 * @property {string} [app_instance_id] - ID of the app instance associated with this conversation
 * @property {string} [created_at] - Timestamp when the conversation was created
 * @property {string} [updated_at] - Timestamp when the conversation was last updated
 * @property {string} [title] - Title of the conversation
 * @property {string} [description] - Description of the conversation
 * @property {Object} [metadata] - Additional metadata associated with the conversation
 * @property {boolean} [is_deleted] - Whether the conversation has been deleted
 * @property {boolean} [is_archived] - Whether the conversation has been archived
 */

/**
 * A conversation file object.
 *
 * @typedef {Object} ConversationFile
 * @property {string} file_id - Unique identifier for the file
 * @property {string} filename - Name of the file
 * @property {string} [url] - URL to access the file
 * @property {string} [file_type] - Type of the file (e.g., 'pdf', 'csv', 'txt')
 * @property {string} [created_at] - Timestamp when the file was added to the conversation
 * @property {string} [conversation_id] - ID of the conversation this file belongs to
 * @property {number} [size] - Size of the file in bytes
 * @property {Object} [metadata] - Additional metadata associated with the file
 * @property {boolean} [is_deleted] - Whether the file has been deleted
 */

/**
 * Options for the useConversation hook.
 * @typedef {Object} UseConversationOptions
 */

/** Initializes and manages a Conversation. */
const useConversation = () => {
  const params = useParams();
  const { conversation_id: paramConversationID } = params ?? {};
  const [conversationId, setConversationId] = useState(paramConversationID || null);

  const [conversation, setConversation] = useState(
    /** @type {Conversation | null} */ (null)
  );
  const [conversationLoaded, setConversationLoaded] = useState(false);

  const [conversationFiles, setConversationFiles] = useState([]);

  const [conversationMessages, setConversationMessages] = useState(
    /** @type {ConversationMessage[]} */ ([])
  );

  /** True when there is a stream in process. */
  const [inProgress, setInProgress] = useState(false);

  /**
   * Loads a conversation by its ID
   * @param {string} [uuid] - Optional UUID of the conversation to load. If not provided, uses the conversationId from state.
   * @returns {Promise<void>}
   */
  const loadConversation = useCallback(async (uuid) => {
    const id = uuid || conversationId;
    if (id) {
      try {
        const response = await getRequest(`/api/get_conversation/${id}`);
        if (response?.data) {
          const { data } = response;
          if (data.conversation) {
            setConversationId(data.conversation.conversation_id);
            setConversation(data.conversation);
          }
          const messages = (data.messages ?? [])
            .map((message) => ({ ...message, completed: true }));

          setConversationMessages(messages);
          setConversationFiles(data.files ?? []);

          setConversationLoaded(true);
        }
      } catch (error) {
        console.error("Error loading conversation:", error);
      }
    }
  }, [conversationId]);

  /**
   * Updates a message in the conversation by applying a transform function to it.
   * If no message is found, a console warning is printed and no action is taken.
   * @param {string|null} messageId - The stream_id of the message to update, or null to update the last assistant message
   * @param {(message: ConversationMessage) => ConversationMessage} transformFn - Function to transform the message
   * @param {(() => ConversationMessage)} [defaultFn] - Optional default function to return a new message if no message is found
   * @param {string} role - The role to look for if messageId is null
   */
  const updateMessage = useCallback((messageId, transformFn, defaultFn, role = "assistant") => {
    if (!conversationId) {
      console.warn("Calling updateMessage without a conversationId.", { messageId });
      return;
    }

    setConversationMessages(prevMessages => {
      // Find the target message
      const targetIndex = prevMessages.findIndex(message =>
        (message.id && message.id === messageId) ||
        (!message.id && message.role == role)
      );
      
      if (targetIndex !== -1) {
        const updatedMessages = [...prevMessages];
        
        // Apply the transform function to the message
        updatedMessages[targetIndex] = transformFn({
          ...prevMessages[targetIndex],
          id: messageId || prevMessages[targetIndex].id
        });

        return updatedMessages;
      } else {
        const newMessage = defaultFn?.();
        return newMessage ? [...prevMessages, newMessage] : prevMessages;
      }
    });
  }, [conversationId, setConversationMessages]);

  /**
   * Updates a message in the conversation with new text content. Creates
   * a message if there is no 
   * @param {string|null} messageId - The stream_id of the message to update, or null to update the last assistant message
   * @param {string} text - The text to append to the message
   */
  const updateMessageText = useCallback((messageId, text, role = "assistant") => {
    if (!conversationId) {
      console.warn("Calling updateMessage without a conversationId.", { messageId, text });
      return;
    }
    console.log(`Updating message with ID ${messageId} with text: ${text}`);

    updateMessage(
      messageId,
      (message) => ({
        ...message,
        message: message.message + text
      }),
      () => {
        /** @type {ConversationMessage} */
        const assistantMessage = {
          message: text,
          conversation_id: conversationId,
          role,
          completed: false,
          uuid: randomUUID(),
          id: messageId,
        };
        return assistantMessage;
      }
    );
  }, [conversationId, updateMessage]);

  /**
   * Marks a message as having an error and sets the error message
   * @param {string|null} messageId - The stream_id of the message to mark as error, or null to update the last assistant message
   * @param {string} errorText - The error message to display
   */
  const errorMessage = useCallback((messageId, errorText = "") => {
    updateMessage(messageId, (message) => ({
      ...message,
      message: "",
      error: true,
      error_message: errorText || "An error occurred while generating a response.",
      completed: true
    }));

    setInProgress(false);
  }, []);

  /**
   * Marks a message as completed
   * @param {string|null} messageId - The stream_id of the message to mark as completed, or null to update the last assistant message
   */
  const completeMessage = useCallback((messageId) => {
    updateMessage(messageId, (message) => ({
      ...message,
      completed: true
    }));

    setInProgress(false);
  }, [updateMessage]);

  /**
   * Sends a user message to the backend and handles the streaming response
   * @param {string | string[]} message - The message or messages to send
   * @param {Object} [options={}] - Additional options for the message
   * @param {string} [options.system] - System prompt to include
   * @param {string[]} [options.files] - Array of file IDs to include
   * @param {Object} [options.user] - User object
   * @param {string} [options.query_type] - Type of query
   * @param {string} [options.file_type] - Type of file
   * @param {boolean} [options.bypass_approved_answers] - Whether to bypass approved answers
   * @param {boolean} [options.find_closest_approved] - Whether to find closest approved answer
   * @param {string} [options.return_type] - Type of return value
   * @param {string} [options.app_instance_id] - App instance ID
   * @returns {Object} The SSE source object that can be used to abort the request
   */
  const sendUserMessage = useCallback((message, options = {}) => {
    if (!conversationId) {
      console.warn("Cannot send user message without a conversation ID", { message });
      return null;
    }

    // Validate message
    if (!message || (Array.isArray(message) && message.length === 0)) {
      console.error("Cannot send an empty message", message);
      return null;
    }

    message = Array.isArray(message) && message.length === 1 ? message[0] : message;
    
    // Create payload from message string and options
    const payload = {
      queries: Array.isArray(message) ? message : undefined,
      query: Array.isArray(message) ? undefined : message,
      streaming: true,
      // Include conversation history from state
      history: conversationMessages.filter((message) => message.completed),
      // Include conversation ID from state
      conversation_id: conversationId,
      // Default values for common options
      get_chunks_only: false,
      // Override with any user-provided options
      ...options
    };

    console.log("Sending message with payload:", payload);

    // Add user message to conversation, if sending one message.
    if (!Array.isArray(message)) {
      /** @type {ConversationMessage} */
      const userMessage = {
        message,
        role: "user",
        completed: false,
        conversation_id: conversationId,
        // This will be filled in with the query_info stream_id (conversation message id).
        id: undefined,
        uuid: randomUUID()
      };

      // Add an empty assistant message to the conversation, which
      // will be updated when streaming text starts.
      /** @type {ConversationMessage} */
      const assistantMessage = {
        message: "",
        role: "assistant",
        completed: false,
        conversation_id: conversationId,
        id: undefined,
        uuid: randomUUID()
      };

      // We'll add the assistant message when we receive the query_info metadata
      // This ensures we have the correct stream_id from the backend
      setConversationMessages(prevMessages => [...prevMessages, userMessage, assistantMessage]);
    }

    setInProgress(true);

    // Use ssePostRequest to handle streaming response
    const source = ssePostRequest(queryChatBotUrl, payload, {
      onStreamingText: (text, messageId) => {
        console.log("Streaming text from backend:", text);
        console.log("Message ID from backend:", messageId);
        // Only update if we have a valid messageId
        if (messageId) {
          updateMessageText(messageId, text);
        } else {
          console.warn("No message ID provided for streaming text");
        }
      },
      onMetadata: (jsonPayload) => {
        const messageId = jsonPayload.value.metadata.stream_id;

        if (jsonPayload.value.type === "query_info") {
          // When we get query_info, update the user's message with the stream_id
          console.log("Received query_info metadata:", jsonPayload.value.metadata);

          updateMessage(
            messageId,
            (message) => ({
              ...message,
              id: jsonPayload.value.metadata.stream_id,
              completed: true,
            }),
            () => ({
              message: jsonPayload.value.metadata.query,
              role: "user",
              completed: true,
              uuid: randomUUID(),
              id: jsonPayload.value.metadata.stream_id,
              conversation_id: conversationId,
            }),
            "user"
          );
        } else if (jsonPayload.value.type === "file_url") {
          updateMessage(
            messageId,
            (message) => ({
              ...message,
              file_url: jsonPayload.value.metadata.url,
              completed: true
            })
          );

          setInProgress(false);

        } else if (jsonPayload.value.type === "approved_answer") {
          updateMessage(
            messageId,
            (message) => ({
              ...message,
              approved_answer: jsonPayload.value.metadata.approved_answer,
              completed: true,
              approved_question: jsonPayload.value.metadata.approved_question,
              dynamic_answer: jsonPayload.value.metadata.dynamic_answer
            })
          );

          setInProgress(false);

        } else if (jsonPayload.value.type === "final_chunks") {
          updateMessage(messageId, (message) => ({
            ...message,
            highlighting: {
              files: jsonPayload.value.metadata.files_based_on_summaries,
              chunks: processChunks(jsonPayload.value.metadata.chunks),
            }
          }));
          setInProgress(false);
        }
      },
      onFinal: async (jsonPayload) => {
        // Mark the last assistant message as completed
        // If we have a stream_id in the final response, use it to find the specific message
        completeMessage(jsonPayload.value?.stream_id ?? undefined);
        setInProgress(false);
      },
      onError: (jsonPayload) => {
        const errorMsg = jsonPayload?.value?.error_message;
        const streamId = jsonPayload?.value?.stream_id;
        errorMessage(streamId, errorMsg);
        setInProgress(false);
      }
    });

    return source;
  }, [conversationId, conversationMessages, setConversationMessages, setInProgress, updateMessageText, completeMessage, errorMessage, updateMessage]);

  /**
   * Creates a new conversation
   * @param {string} appInstanceId - The ID of the app instance
   * @returns {Promise<void>}
   */
  const createConversation = useCallback(
    async (appInstanceId) => {
      if (!conversationId && !conversationLoaded) {
        try {
          const { data } = await postRequest("/api/add_conversation", { app_instance_id: appInstanceId });
          setConversationId(data.conversation_id);
          setConversationLoaded(true);
          return data;
        } catch (error) {
          console.error("Failed to create Conversation:", error);
          throw error;
        }
      }
    },
    [
      conversationId,
      conversationLoaded,
    ]
  );

  /**
   * Deletes a conversation file
   * @param {string} fileId - The ID of the file to delete
   * @returns {Promise<void>}
   */
  const deleteConversationFile = useCallback(async (fileId) => {
    if (!conversationId || !fileId) {
      console.error("Missing required parameters for file deletion.");
      return;
    }

    try {
      await deleteRequest(`/api/conversation/${conversationId}/file/${fileId}`);
    } catch (error) {
      console.error("Error deleting conversation file:", error);
    }
  }, [conversationId]);

  return {
    // State
    conversationId,
    conversation,
    conversationMessages,
    conversationFiles,
    inProgress,

    // Message handling functions
    sendUserMessage,
    updateMessageText,
    updateMessage,
    errorMessage,
    completeMessage,

    // Conversation management functions
    createConversation,
    loadConversation,
    deleteConversationFile,
  };
};

export default useConversation;
