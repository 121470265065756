import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import FootnotesList from "./FootnotesList";
import { useUser } from "@clerk/clerk-react";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import useFetchFootnotes from "./useFetchFootnotes";
import useFetchFootnoteFiles from "./useFetchFootnoteFiles";
import { toTitleCase } from "../../utils";
import { formatNum } from "../../utils";
import { MessageForm, Select } from "../../components";
import useFetchFootnoteFiltersData from "../../hooks/useFetchFootnoteFiltersData";
import SignInModal from "../../utils/SignInModal";
import BlackComputerScreen from "../../utils/BlackComputerScreen";
import Progress from "../../utils/Progress";
import { Link } from "react-router-dom";
import samples from "./samples.json";

const FootnotesDatabase = ({ isPublic = false }) => {
  console.log("FootnotesDatabase isPublic:", isPublic); // Add this log

  const [query, setQuery] = useState("");

  // States used for filtering
  const [selectedFootnoteTitles, setSelectedFootnoteTitles] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedAccountingBasis, setSelectedAccountingBasis] = useState([]);
  const [sampleQueryClicked, setSampleQueryClicked] = useState(false);
  const [queryRunning, setQueryRunning] = useState(false);
  const selectedFootnoteTitleValues = useMemo(
    () => selectedFootnoteTitles.map(({ value }) => value),
    [selectedFootnoteTitles]
  );
  const selectedCompanyValues = useMemo(
    () => selectedCompanies.map(({ value }) => value),
    [selectedCompanies]
  );
  const selectedYearValues = useMemo(
    () => selectedYear.map(({ value }) => value),
    [selectedYear]
  );
  const selectedIndustryValues = useMemo(
    () => selectedIndustry.map(({ value }) => value),
    [selectedIndustry]
  );
  const selectedAccountingBasisValues = useMemo(
    () => selectedAccountingBasis.map(({ value }) => value),
    [selectedAccountingBasis]
  );
  // End of states used for filtering

  const { footnoteFiles } = useFetchFootnoteFiles();
  const { appInstanceData } = useAppInstanceData();

  const {
    accountingBasis,
    companies,
    footnoteTitles,
    industries,
    refetch: refetchFiltersData,
    years,
  } = useFetchFootnoteFiltersData({
    isPublic,
    appInstanceID: appInstanceData.app_instance_id,
  });

  const {
    companiesCount,
    error: footnotesError,
    hasFilters,
    fetchFootnotes,
    fetchFootnotesWithQuery,
    footnotes,
    footnotesCount,
    hasNextPage,
    hasPreviousPage,
    loading,
    messagesFromBackend,
    navigateNext,
    navigatePrevious,
    pageSize,
    progressPercentage,
    resetToFirstPage,
    setPageSize,
  } = useFetchFootnotes(query, {
    footnoteTitles: selectedFootnoteTitleValues,
    companies: selectedCompanyValues,
    years: selectedYearValues,
    industries: selectedIndustryValues,
    accountingBasis: selectedAccountingBasisValues,
    isPublic,
    appInstanceID: appInstanceData.app_instance_id,
  });

  const showNoFootnotesMessage = hasFilters && footnotesCount === 0 && !loading;
  const showErrorMessage = footnotesError && !loading;

  useEffect(() => {
    // Reset states when appInstanceData.id or isPublic changes
    setSelectedFootnoteTitles([]);
    setSelectedCompanies([]);
    setSelectedYear([]);
    setSelectedIndustry([]);
    setSelectedAccountingBasis([]);
    setSampleQueryClicked(false);
    setQueryRunning(false);
    resetToFirstPage(); // Reset to the first page of results
    refetchFiltersData();
  }, [isPublic, appInstanceData.app_instance_id]);

  const { isSignedIn } = useUser();
  const { addNotification } = useNotifications();

  const handleSubmitMessageForm = useCallback(
    async (e) => {
      e.preventDefault();

      if (!isSignedIn) {
        console.log("Not signed in.");
        setSignInModalOpen(true);
      } else {
        resetToFirstPage();
        setQueryRunning(true);
        try {
          await fetchFootnotes(query);
        } catch (error) {
          addNotification(
            "Error submitting your query.",
            "",
            NotificationType.error
          );
          console.error("Error fetching data:", error);
        } finally {
          setQueryRunning(false);
        }
      }
    },
    [isSignedIn, query, fetchFootnotes, resetToFirstPage]
  );

  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const onChangePageSize = useCallback(
    (e) => {
      setPageSize(parseInt(e.target.value));
    },
    [setPageSize]
  );

  const handleSelectionChange = useCallback(
    (option, setSelectedFunction) => {
      /**
       * IMPORTANT
       * This callback will internally trigger a refetch of the footnotes data
       * DO NOT call fetchFootnotes here directly
       */
      setSelectedFunction(option);
      resetToFirstPage(); // Reset page number
    },
    [resetToFirstPage]
  );

  const setSelectedFootnoteTitleInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedFootnoteTitles),
    [setSelectedFootnoteTitles, handleSelectionChange]
  );

  const setSelectedCompanyInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedCompanies),
    [setSelectedCompanies, handleSelectionChange]
  );

  const setSelectedYearInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedYear),
    [setSelectedYear, handleSelectionChange]
  );

  const setSelectedIndustryInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedIndustry),
    [setSelectedIndustry, handleSelectionChange]
  );

  const setSelectedAccountingBasisInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedAccountingBasis),
    [setSelectedAccountingBasis, handleSelectionChange]
  );

  let enterOrFiltersClicked =
    selectedIndustry.length > 0 ||
    selectedFootnoteTitles.length > 0 ||
    selectedCompanies.length > 0 ||
    selectedYear.length > 0 ||
    selectedAccountingBasis.length > 0 ||
    query;

  const footnoteDataWithFiles = useMemo(() => {
    if (footnotes && footnotes.length > 0) {
      //console.log("footnotes", footnotes);
      const mappedFootnotes = footnotes?.map((footnote) => {
        const pdfFile = footnoteFiles?.find(
          (file) => file.file_id === footnote.pdf_file_id
        );
        const screenshotFile = footnoteFiles?.find(
          (file) => file.file_id === footnote.screenshot_file_id
        );
        return {
          ...footnote,
          pdfFile,
          screenshotFile,
        };
      });

      return mappedFootnotes;
    } else return [];
  }, [footnotes, footnoteFiles]);

  return (
    <>
      <SignInModal open={signInModalOpen} setOpen={setSignInModalOpen} />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            <h1 className="text-4xl font-bold text-center text-customHighlightColor mb-4">
              {isPublic ? "FootNoteDB 10Ks" : "FootNoteDB Private"}
            </h1>
            <p className="text-center text-gray-600 mb-4 text-xl">
              {isPublic ? (
                "A comprehensive database of footnotes from 10K filings."
              ) : (
                <>
                  A database of your company's footnotes.
                  <br />
                  <Link
                    className="text-sm text-customHighlightColor no-underline hover:underline"
                    to={`/app/edit/${appInstanceData.app_instance_id}`}
                  >
                    Edit Source Documents
                  </Link>
                </>
              )}
            </p>

            <>
              <div
                className="flex py-2 align-middle min-w-full sm:px-6 lg:px-8"
              >
                <div className="flex flex-wrap gap-2 w-full justify-start">
                  <div className="w-48 shrink-0">
                    <Select
                      label="Filing Year"
                      options={years?.map(({ year }) => ({
                        value: year,
                        label: year,
                      }))}
                      value={selectedYear}
                      onChange={setSelectedYearInternal}
                      isClearable
                      isMulti
                      placeholder="Filing Year"
                    />
                  </div>
                  <div className="w-48">
                    <Select
                      label="Company"
                      options={companies?.map(({ name }) => ({
                        value: name,
                        label: name,
                      }))}
                      value={selectedCompanies}
                      onChange={setSelectedCompanyInternal}
                      isClearable
                      isMulti
                      placeholder="Company"
                    />
                  </div>
                  <div className="w-48">
                    <Select
                      label="Topic"
                      options={footnoteTitles?.map(({ title }) => ({
                        value: title,
                        label: toTitleCase(title),
                      }))}
                      value={selectedFootnoteTitles}
                      onChange={setSelectedFootnoteTitleInternal}
                      isClearable
                      isMulti
                      placeholder="Topic"
                    />
                  </div>
                  <div className="w-48">
                    <Select
                      label="Industry"
                      options={industries?.map(({ name }) => ({
                        value: name,
                        label: name,
                      }))}
                      value={selectedIndustry}
                      onChange={setSelectedIndustryInternal}
                      isClearable
                      isMulti
                      placeholder="Industry"
                    />
                  </div>
                  <div className="w-48">
                    <Select
                      label="Accounting Basis"
                      options={accountingBasis?.map((value) => ({
                        value: value,
                        label: value,
                      }))}
                      value={selectedAccountingBasis}
                      onChange={setSelectedAccountingBasisInternal}
                      isClearable
                      isMulti
                      placeholder="Accounting Basis"
                    />
                  </div>
                </div>
              </div>

              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <MessageForm
                  value={query}
                  onChange={({ target: { value } }) => {
                    setQuery(value);
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    // Update the query when form is submitted
                    handleSubmitMessageForm(e);
                  }}
                  className="mb-4"
                  placeholder="Please describe, in your own words, the type of disclosure you are seeking."
                />{" "}
                {loading && query !== "" && messagesFromBackend.length > 0 && (
                  <>
                    <BlackComputerScreen lines={messagesFromBackend} />
                    <Progress percentage={progressPercentage} />
                  </>
                )}
              </div>
              <>

                <div className="flex py-2 align-middle text-sm text-gray-600 inline-block min-w-full sm:px-6 lg:px-8 h-10">
                  {footnotesCount > 0 && <>
                    {formatNum(footnotesCount)} footnotes from{" "}
                    {formatNum(companiesCount)} {isPublic ? "public" : null}{" "}
                    companies.
                  </>}
                </div>
                {showNoFootnotesMessage && (
                  <div className="flex py-2 align-middle text-sm text-gray-600 inline-block min-w-full sm:px-6 lg:px-8">
                    No footnotes found for the selected filters.
                  </div>
                )}
                {showErrorMessage && (
                  <div className="flex py-2 align-middle text-sm text-gray-600 inline-block min-w-full sm:px-6 lg:px-8">
                    Error fetching footnotes. Please try again.
                  </div>
                )}
                {enterOrFiltersClicked && (!queryRunning || sampleQueryClicked) ? (
                  <>
                    <FootnotesList
                      footnotes={footnoteDataWithFiles}
                      isPublic={isPublic}
                      isLoading={loading}
                    />
                    <div className="flex justify-between mt-2">
                      <div className="flex gap-2 mt-4">
                        {(hasPreviousPage || hasNextPage) && (
                          <>
                            <button
                              onClick={navigatePrevious}
                              disabled={!hasPreviousPage || loading}
                              className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                            >
                              Previous
                            </button>
                            <button
                              onClick={navigateNext}
                              disabled={!hasNextPage || loading}
                              className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                            >
                              Next
                            </button>
                          </>
                        )}
                      </div>
                      <div className="flex gap-2 items-center">
                        <label htmlFor="pageSize">Page Size</label>
                        <select
                          type="number"
                          id="pageSize"
                          value={pageSize}
                          onChange={onChangePageSize}
                          className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor bg-white cursor-pointer transition-colors duration-300 focus:border-blue-500 focus:outline-none"
                        >
                          <option value={5}>5</option>
                          <option value={10} selected>
                            10
                          </option>
                          <option value={25}>25</option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex h-screen gap-4">
                    {/* Left Column - Trends */}
                    <div className="w-1/2 overflow-y-auto border-r border-gray-300 p-4">
                      <h2 className="text-xl font-semibold text-gray-700 mb-4">
                        {/* Trends */}
                        Sample Queries
                      </h2>
                      {samples.map((sample) => (
                        <div
                          key={sample.id}
                          className="cursor-pointer p-4 mb-4 bg-white rounded-lg border-2 border-gray-300 transition-all hover:border-purple-700"
                          onClick={() => {
                            setQuery(sample.title);
                            resetToFirstPage();
                            fetchFootnotesWithQuery(sample.title);
                            setSampleQueryClicked(true);
                          }}
                        >
                          <span className="text-gray-700 block mt-1 hover:text-purple-700">
                            {sample.title}
                          </span>
                        </div>
                      ))}
                    </div>

                    {/* Right Column - Disclosures */}
                    <div className="w-1/2 overflow-y-auto p-6 bg-white rounded-lg shadow-lg">
                      <h2 className="text-xl font-semibold text-gray-700 mb-4">
                        Footnotes By Type
                      </h2>
                      {footnoteTitles?.slice(0, 10).map((disclosure, index) => (
                        <div
                          key={index}
                          className="flex justify-between mb-3 p-2 hover:bg-gray-100 rounded"
                          onClick={() => {
                            setSelectedFootnoteTitleInternal([
                              {
                                value: disclosure.title,
                                label: toTitleCase(disclosure.title),
                              },
                            ]);
                          }}
                        >
                          <span className="text-gray-700 hover:text-purple-700 cursor-pointer">
                            {toTitleCase(disclosure.title)}
                          </span>
                          <span className="text-gray-500">
                            {formatNum(disclosure.title_count)} results
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default FootnotesDatabase;
