import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../redux/reducers/app.reducer";
import { postRequest } from "../utils/httpUtils";
import { formatTimestamp } from "../utils";

// Debug logger helper
const debug = {
  log: (message, data = null) => {
    const timestamp = new Date().toISOString();
    const prefix = `[Logs Component ${timestamp}]`;
    
    if (data) {
      console.log(`${prefix} ${message}`, data);
    } else {
      console.log(`${prefix} ${message}`);
    }
  },
  error: (message, error = null) => {
    const timestamp = new Date().toISOString();
    const prefix = `[Logs Component ERROR ${timestamp}]`;
    
    if (error) {
      console.error(`${prefix} ${message}`, error);
    } else {
      console.error(`${prefix} ${message}`);
    }
  }
};

const Logs = () => {
  debug.log("Component rendering");
  
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    debug.log("Auth state changed", { isSignedIn, isLoaded });
    
    if (isLoaded && isSignedIn) {
      debug.log("Dispatching log event");
      dispatch(
        logEvent({
          user,
          event: "Looked at users table.",
        })
      );
    }
  }, [isLoaded, isSignedIn, user, dispatch]);

  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [slackQuestions, setSlackQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      debug.log("Fetching data started");
      
      try {
        debug.log("Making API request", { user });
        
        const response = await postRequest("/api/admin/users", { user: user });

        debug.log("API response received", { 
          usersCount: (response.data.users || []).length,
          eventsCount: (response.data.events || []).length,
          slackEventsCount: (response.data.slack_events || []).length
        });
        
        // Additional logging for event structure
        if (response.data.events && response.data.events.length > 0) {
          debug.log("First event object structure", response.data.events[0]);
          
          if (response.data.events[0].events) {
            debug.log("Nested events found", {
              count: response.data.events[0].events.length,
              firstNestedEvent: response.data.events[0].events[0]
            });
          }
        }

        
        setUsers(response.data["users"] || []);
        setEvents(response.data["events"] || []);
        setSlackQuestions(
          (response.data.slack_events || [])
            .flatMap((question) => question.events)
            .filter((event) => event !== null)
        );
        
        debug.log("State updated with API data");
      } catch (error) {

        debug.error("API request failed", error);

        console.error("API Error:", error);
        if (error.response) {
          debug.error("Error response", {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers
          });
        } else if (error.request) {
          debug.error("Error request", error.request);
        } else {
          debug.error(`Error message: ${error.message}`);
        }
        debug.error("Error config", error.config);
      }
    };

    if (isSignedIn) {
      fetchData();
    }
  }, [isLoaded, isSignedIn, user]);

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (userId) => {
    debug.log(`Row clicked: ${userId}, current expanded: ${expandedRow}`);
    setExpandedRow(expandedRow === userId ? null : userId);
  };

  // Handle the nested events structure
  const getUserEvents = (userId) => {
    debug.log(`Getting events for user ID: ${userId}`);
    
    if (!events || events.length === 0) {
      debug.log("No events available");
      return [];
    }
    
    const userData = users.find(u => u.id === userId);
    if (!userData) {
      debug.log(`User data not found for ID: ${userId}`);
      return [];
    }
    
    debug.log(`Found user data`, {
      id: userData.id,
      user_id: userData.user_id,
      email: userData.email
    });
    
    // Find event objects that match this user
    const matchingEventObjects = events.filter(eventObj => 
      eventObj.user_id === userData.user_id
    );
    
    debug.log(`Found ${matchingEventObjects.length} matching event objects`);
    
    // Extract all nested events from the matching event objects
    const allUserEvents = [];
    
    matchingEventObjects.forEach(eventObj => {
      // Check if there's a nested events array
      if (eventObj.events && Array.isArray(eventObj.events)) {
        debug.log(`Adding ${eventObj.events.length} nested events from object`, { 
          eventObj_id: eventObj.id,
          user_id: eventObj.user_id
        });
        allUserEvents.push(...eventObj.events);
      } else {
        debug.log(`No nested events in object`, { 
          eventObj_id: eventObj.id,
          user_id: eventObj.user_id
        });
      }
    });
    
    debug.log(`Total events found for user: ${allUserEvents.length}`);
    return allUserEvents;
  };


  // Helper function to format timestamp with timezone
  const formatTimestampWithZone = (timestamp) => {
    if (!timestamp) return 'No timestamp';
    
    try {
      const date = new Date(timestamp);
      
      // Get timezone offset
      const timeZoneOffset = date.getTimezoneOffset();
      const offsetHours = Math.abs(Math.floor(timeZoneOffset / 60));
      const offsetSign = timeZoneOffset > 0 ? '-' : '+';
      
      // Format date and time
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      
      return `${dateString}  ${timeString} (UTC${offsetSign}${offsetHours})`;
    } catch (e) {
      debug.error("Error formatting timestamp", { timestamp, error: e });

      return 'Invalid Date';
    }
  };

  const TableRow = ({ user, expanded, toggle }) => {
    const userEvents = getUserEvents(user.id);
    
    return (
      <>
        <tr onClick={toggle} className="cursor-pointer hover:bg-gray-100">
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="h-11 w-11 flex-shrink-0">
                <img
                  className="h-11 w-11 rounded-full"
                  src={user.image}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">{user.name}</div>
                <div className="mt-1 text-gray-500">{user.email}</div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <div className="text-gray-900">
              {user.last_active === user.signed_up ? "-" : user.last_active}
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
            <div className="text-gray-900">{user.signed_up}</div>
          </td>
        </tr>
        {expanded && (
          <tr>
            <td colSpan="3" className="bg-gray-50 p-0">
              <div className="py-2 px-4">
                {/* Events count */}
                <div className="text-xs text-gray-500 mb-2">
                  Events found: {userEvents.length}
                </div>
                

                {/* Fixed height container with scrolling */}
                <div className="border border-gray-200 rounded" style={{ height: '250px', overflow: 'hidden' }}>
                  <div className="h-full overflow-y-auto" style={{ paddingRight: '6px' }}>
                    <div className="divide-y divide-gray-200">
                      {userEvents.length === 0 ? (
                        <div className="py-3 px-3">
                          <div className="text-center text-sm text-gray-500">
                            No events to display
                          </div>
                        </div>
                      ) : (
                        userEvents.map((event, index) => (
                          <div key={index} className="py-3 px-3">
                            <div className="flex flex-col">
                              <div className="text-sm text-gray-500 mb-1">
                                {event.timestamp ? (
                                  <span>
                                    {formatTimestampWithZone(event.timestamp)}
                                  </span>
                                ) : (
                                  <span>No timestamp</span>
                                )}
                              </div>
                              <div className="text-sm text-gray-900">
                                {event.event}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <h1 className="text-xl font-bold mb-4">Logs</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name and Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Active
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Signed Up
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users &&
                  users.map((user) => (
                    <TableRow
                      key={user.email}
                      user={user}
                      expanded={expandedRow === user.id}
                      toggle={() => handleRowClick(user.id)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        
        <div className="mt-8">
          {slackQuestions.length > 0 && (
            <>
              <h2 className="text-lg font-bold mb-4">Slack Events</h2>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-3/4 px-3 py-3 text-left text-sm font-semibold text-gray-900">Event</th>
                    <th className="w-1/4 px-3 py-3 text-left text-sm font-semibold text-gray-900">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {slackQuestions.map((event, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="py-3 px-3 text-sm text-gray-500 break-words">
                        {event.event}
                      </td>
                      <td className="py-3 px-3 text-sm text-gray-500">
                        {event.timestamp ? formatTimestamp(event.timestamp) : 'No timestamp'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Logs;